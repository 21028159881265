html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
:root {
  --ratio: 1.125;
  --fz-ratio--four: calc(1rem * var(--ratio) * var(--ratio) * var(--ratio) * var(--ratio));
  --fz-ratio--three: calc(1rem * var(--ratio) * var(--ratio) * var(--ratio));
  --fz-ratio--two: calc(1rem * var(--ratio) * var(--ratio));
  --fz-ratio--one: calc(1rem * var(--ratio));
  --fz-ratio--minus-one: calc(1rem / var(--ratio));
  --fz-ratio--minus-two: calc((1rem / var(--ratio)) / var(--ratio));
  --sp-ratio--four: calc(var(--fz-ratio--four) * 1.4);
  --sp-ratio--three: calc(var(--fz-ratio--three) * 1.4);
  --sp-ratio--two: calc(var(--fz-ratio--two) * 1.4);
  --sp-ratio--one: calc(var(--fz-ratio--one) * 1.4);
}
@media only screen and (min-width: 681px) {
  :root {
    --ratio: 1.2125;
  }
}
@media only screen and (min-width: 1281px) {
  :root {
    --ratio: 1.3;
  }
}
@-moz-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes slide-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@-webkit-keyframes slide-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@-o-keyframes slide-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slide-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@-moz-keyframes countdown-blip {
  0% {
    color: #666;
  }
  50% {
    color: #555;
  }
}
@-webkit-keyframes countdown-blip {
  0% {
    color: #666;
  }
  50% {
    color: #555;
  }
}
@-o-keyframes countdown-blip {
  0% {
    color: #666;
  }
  50% {
    color: #555;
  }
}
@keyframes countdown-blip {
  0% {
    color: #666;
  }
  50% {
    color: #555;
  }
}
@-moz-keyframes hero02 {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  33% {
    opacity: 1;
  }
  63% {
    opacity: 1;
  }
  66% {
    opacity: 1;
  }
  96% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes hero02 {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  33% {
    opacity: 1;
  }
  63% {
    opacity: 1;
  }
  66% {
    opacity: 1;
  }
  96% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes hero02 {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  33% {
    opacity: 1;
  }
  63% {
    opacity: 1;
  }
  66% {
    opacity: 1;
  }
  96% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes hero02 {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  33% {
    opacity: 1;
  }
  63% {
    opacity: 1;
  }
  66% {
    opacity: 1;
  }
  96% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes hero03 {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  33% {
    opacity: 0;
  }
  63% {
    opacity: 0;
  }
  66% {
    opacity: 1;
  }
  96% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes hero03 {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  33% {
    opacity: 0;
  }
  63% {
    opacity: 0;
  }
  66% {
    opacity: 1;
  }
  96% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes hero03 {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  33% {
    opacity: 0;
  }
  63% {
    opacity: 0;
  }
  66% {
    opacity: 1;
  }
  96% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes hero03 {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  33% {
    opacity: 0;
  }
  63% {
    opacity: 0;
  }
  66% {
    opacity: 1;
  }
  96% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
.contain {
  display: block;
  width: 92%;
  max-width: 880px;
  margin-right: auto;
  margin-left: auto;
}
html * {
/*
        the below line should be commented out because we have exceeded most browsers'
        will-change budget allowance. we can fix this by only using one or
        two properties as a default and then specifying properties every time
        we call the mixin, but at present removing default properties from this
        mixin causes many of the site's styles to break, and actually commenting
        out the line also causes styles to break.
    */
  transition-property: outline-offset, background-color, box-shadow, color, opacity;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  outline: 0 dashed #fcbf30;
  outline-offset: 0;
}
html[data-focus-source="key"] *:focus,
html[data-focus-source="script"] *:focus {
  z-index: 1;
  outline: 2px dashed #fcbf30;
  outline-offset: 6px;
  overflow: visible;
}
html[data-focus-source="pointer"] *:focus,
html[data-focus-source=""] *:focus {
  outline: 0;
}
*,
*::before,
*::after {
  box-sizing: border-box;
  word-wrap: break-word;
}
body {
  will-change: unset;
  transform: none;
  filter: none;
  position: relative;
  z-index: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  width: 100%;
  max-width: 100%;
  background-color: #f1f3f9;
}
.content {
/*
        the below line should be commented out because we have exceeded most browsers'
        will-change budget allowance. we can fix this by only using one or
        two properties as a default and then specifying properties every time
        we call the mixin, but at present removing default properties from this
        mixin causes many of the site's styles to break, and actually commenting
        out the line also causes styles to break.
    */
  transition-property: outline-offset, margin-top;
  transition-duration: 0.4s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  z-index: 10;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
}
.global_search_is_visible .content {
  margin-top: 6.472rem;
}
.page_content {
  flex: 1 0 auto;
  width: 100%;
}
@media all and (-ms-high-contrast: none) {
  .page_content {
    flex: 0 0 auto;
  }
}
.page_content > *:not(.ignore_spacing--top) {
  margin-top: 3.236rem;
}
.page_content > *:last-child:not(.ignore_spacing--bottom) {
  margin-bottom: 3.236rem;
}
.fourohfour .partner-block {
  margin-top: 4.854rem;
}
.fourohfour h1 {
  font-size: 1.265625rem;
  font-size: var(--fz-ratio--two);
}
@media only screen and (min-width: 681px) {
  .fourohfour h1 {
    font-size: 1.47015625rem;
    font-size: var(--fz-ratio--two);
  }
}
@media only screen and (min-width: 1281px) {
  .fourohfour h1 {
    font-size: 1.69rem;
    font-size: var(--fz-ratio--two);
  }
}
img {
  display: block;
  height: auto;
  width: auto;
  max-width: 100%;
  vertical-align: bottom;
}
* + img {
  margin-top: 1.618rem;
}
input,
button {
/*
        the below line should be commented out because we have exceeded most browsers'
        will-change budget allowance. we can fix this by only using one or
        two properties as a default and then specifying properties every time
        we call the mixin, but at present removing default properties from this
        mixin causes many of the site's styles to break, and actually commenting
        out the line also causes styles to break.
    */
  transition-property: outline-offset, background-color, box-shadow, color, opacity;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}
button {
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding: 0;
  font: inherit;
  color: inherit;
}
:root {
  font-size: 14px;
}
@media screen and (min-width: 320px) {
  :root {
    font-size: calc(0.5063291139240507vw + 12.379746835443038px);
  }
}
@media screen and (min-width: 1900px) {
  :root {
    font-size: 22px;
  }
}
html {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body,
select,
input,
textarea {
  font-family: 'roboto-condensed', sans-serif;
  font-weight: 400;
  line-height: 1.618;
  font-size: 1rem;
  letter-spacing: -0.016em;
  color: #555;
}
h1,
h2,
h3,
h4 {
  display: block;
  max-width: 100%;
  font-family: 'roboto-condensed', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: -0.016em;
  color: #555;
  margin: 0;
}
h1 svg,
h2 svg,
h3 svg,
h4 svg {
  fill: #555;
  width: auto;
  height: 54px;
  margin-left: 0.4045rem;
}
h1 {
  font-family: 'tandelle', sans-serif;
  font-weight: 400;
  max-width: 39ch;
  line-height: 0.9;
  color: #555;
}
h1 {
  font-size: 60px;
}
@media screen and (min-width: 320px) {
  h1 {
    font-size: calc(11.39240506329114vw + 23.54430379746836px);
  }
}
@media screen and (min-width: 1900px) {
  h1 {
    font-size: 240px;
  }
}
h2 {
  font-size: 1.423828125rem;
  font-size: var(--fz-ratio--three);
}
@media only screen and (min-width: 681px) {
  h2 {
    font-size: 1.782564453125rem;
    font-size: var(--fz-ratio--three);
  }
}
@media only screen and (min-width: 1281px) {
  h2 {
    font-size: 2.197000000000001rem;
    font-size: var(--fz-ratio--three);
  }
}
* + h2 {
  margin-top: 1.771875rem;
  margin-top: var(--sp-ratio--two);
}
@media only screen and (min-width: 681px) {
  * + h2 {
    margin-top: 2.05821875rem;
    margin-top: var(--sp-ratio--two);
  }
}
@media only screen and (min-width: 1281px) {
  * + h2 {
    margin-top: 2.366rem;
    margin-top: var(--sp-ratio--two);
  }
}
h3 {
  font-size: 1.265625rem;
  font-size: var(--fz-ratio--two);
}
@media only screen and (min-width: 681px) {
  h3 {
    font-size: 1.47015625rem;
    font-size: var(--fz-ratio--two);
  }
}
@media only screen and (min-width: 1281px) {
  h3 {
    font-size: 1.69rem;
    font-size: var(--fz-ratio--two);
  }
}
* + h3 {
  margin-top: 1.771875rem;
  margin-top: var(--sp-ratio--two);
}
@media only screen and (min-width: 681px) {
  * + h3 {
    margin-top: 2.05821875rem;
    margin-top: var(--sp-ratio--two);
  }
}
@media only screen and (min-width: 1281px) {
  * + h3 {
    margin-top: 2.366rem;
    margin-top: var(--sp-ratio--two);
  }
}
h4 {
  font-size: 1.125rem;
  font-size: var(--fz-ratio--one);
}
@media only screen and (min-width: 681px) {
  h4 {
    font-size: 1.2125rem;
    font-size: var(--fz-ratio--one);
  }
}
@media only screen and (min-width: 1281px) {
  h4 {
    font-size: 1.3rem;
    font-size: var(--fz-ratio--one);
  }
}
* + h4 {
  margin-top: 1.575rem;
  margin-top: var(--sp-ratio--one);
}
@media only screen and (min-width: 681px) {
  * + h4 {
    margin-top: 1.6975rem;
    margin-top: var(--sp-ratio--one);
  }
}
@media only screen and (min-width: 1281px) {
  * + h4 {
    margin-top: 1.82rem;
    margin-top: var(--sp-ratio--one);
  }
}
p {
  width: auto;
  max-width: 100%;
  font-family: 'roboto-condensed', sans-serif;
  font-size: 1rem;
  line-height: 1.618;
  letter-spacing: -0.016em;
  color: #555;
}
* + p {
  margin-top: 1.618rem;
}
h1 + p,
h2 + p,
h3 + p {
  margin-top: 0.539333333333333rem;
}
h4 + p {
  margin-top: 0.809rem;
}
.reading_content p,
p.reading_content,
.wysiwyg p {
  width: 100%;
  max-width: 78ch;
}
p a,
p a:visited {
  box-shadow: inset 0 -1px rgba(85,85,85,0.3);
  color: #555;
}
p a:active,
p a:focus,
p a:hover {
  box-shadow: inset 0 -0.2em rgba(85,85,85,0.6);
  color: #333;
}
.reading_content ol,
.wysiwyg ol,
.reading_content ul,
.wysiwyg ul {
  display: block;
  width: 78ch;
  max-width: 100%;
  padding-left: 3ch;
}
* + .reading_content ol,
* + .wysiwyg ol,
* + .reading_content ul,
* + .wysiwyg ul {
  margin-top: 1.618rem;
}
.reading_content ol:not(first-child),
.wysiwyg ol:not(first-child),
.reading_content ul:not(first-child),
.wysiwyg ul:not(first-child) {
  margin-top: 1.618rem;
}
.reading_content ol a,
.wysiwyg ol a,
.reading_content ul a,
.wysiwyg ul a,
.reading_content ol a:visited,
.wysiwyg ol a:visited,
.reading_content ul a:visited,
.wysiwyg ul a:visited {
  box-shadow: inset 0 -1px rgba(85,85,85,0.3);
  color: #555;
}
.reading_content ol a:active,
.wysiwyg ol a:active,
.reading_content ul a:active,
.wysiwyg ul a:active,
.reading_content ol a:focus,
.wysiwyg ol a:focus,
.reading_content ul a:focus,
.wysiwyg ul a:focus,
.reading_content ol a:hover,
.wysiwyg ol a:hover,
.reading_content ul a:hover,
.wysiwyg ul a:hover {
  box-shadow: inset 0 -0.2em rgba(85,85,85,0.6);
  color: #333;
}
.reading_content ol,
.wysiwyg ol {
  list-style-type: decimal;
}
.reading_content ol ol,
.wysiwyg ol ol {
  list-style-type: lower-alpha;
}
.reading_content ol ol ol,
.wysiwyg ol ol ol {
  list-style-type: lower-roman;
}
.reading_content ul,
.wysiwyg ul {
  list-style-type: disc;
}
.reading_content ul ul,
.wysiwyg ul ul {
  list-style-type: circle;
}
.reading_content ul ul ul,
.wysiwyg ul ul ul {
  list-style-type: square;
}
.reading_content li,
.wysiwyg li {
  max-width: 100%;
}
.reading_content li + li,
.wysiwyg li + li {
  margin-top: 0.809rem;
}
a,
a:visited {
/*
        the below line should be commented out because we have exceeded most browsers'
        will-change budget allowance. we can fix this by only using one or
        two properties as a default and then specifying properties every time
        we call the mixin, but at present removing default properties from this
        mixin causes many of the site's styles to break, and actually commenting
        out the line also causes styles to break.
    */
  transition-property: outline-offset, background-color, box-shadow, color, opacity;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  cursor: pointer;
  text-decoration: none;
  color: #333;
}
b,
strong {
  font-weight: 700;
}
i,
em {
  font-style: italic;
}
::selection {
  background-color: #181818;
  color: #fff;
}
ul,
ol {
  list-style-type: none;
}
.written_content_list {
  display: flex;
  margin-right: auto;
  margin-left: auto;
  width: 92%;
  max-width: 1180px;
  flex-direction: column;
  width: 92%;
  padding-left: 3ch;
}
* + .written_content_list {
  margin-top: 1.618rem;
}
ol.written_content_list {
  list-style-type: decimal;
}
ul.written_content_list {
  list-style-type: disc;
}
.written_content_list li {
  width: 78ch;
  max-width: 100%;
}
.written_content_list li + li {
  margin-top: 0.809rem;
}
sup {
  vertical-align: super;
  font-size: 0.888888888888889rem;
  font-size: var(--fz-ratio--minus-one);
}
@media only screen and (min-width: 681px) {
  sup {
    font-size: 0.824742268041237rem;
    font-size: var(--fz-ratio--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  sup {
    font-size: 0.769230769230769rem;
    font-size: var(--fz-ratio--minus-one);
  }
}
address {
  font-size: inherit;
  font-style: normal;
  color: #555;
}
address strong {
  font-weight: 700;
}
.footer {
  flex: 0 0 auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: auto;
  background-color: #e5e9f1;
}
.footer__main_content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
  width: 92%;
  max-width: 1600px;
  padding: 1.618rem 0;
}
@media only screen and (min-width: 881px) {
  .footer__main_content {
    flex-direction: row;
  }
}
@media only screen and (min-width: 881px) {
  .footer__main_content {
    align-items: flex-start;
  }
}
@media only screen and (min-width: 881px) {
  .footer__logo {
    transform: translateY(8px);
  }
}
.footer__logo.logo {
  flex: 0 1 auto;
}
@media only screen and (min-width: 881px) {
  .footer__logo.logo {
    margin: 0 1.618rem 0 0;
  }
}
.footer__logo svg {
  height: 2.9rem;
  width: 6rem;
}
.footer__navigation {
  flex: 0 0 auto;
  max-width: 22rem;
}
@media only screen and (max-width: 880px) {
  * + .footer__navigation {
    margin-top: 0.809rem;
  }
}
@media only screen and (min-width: 881px) {
  .footer__navigation {
    margin: 0 auto -0.269666666666667rem;
  }
}
.footer__navigation_item {
  display: block;
  line-height: 2;
}
.footer__navigation_anchor,
.footer__navigation_anchor:link,
.footer__navigation_anchor:visited {
  box-shadow: inset 0 -1px rgba(51,51,51,0.2);
  color: #333;
}
.footer__navigation_anchor:hover {
  box-shadow: inset 0 -0.24em rgba(102,102,102,0.2);
  color: #333;
}
.footer__navigation_anchor:active,
.footer__navigation_anchor:focus {
  box-shadow: inset 0 -0.14em rgba(24,24,24,0.2);
  color: #181818;
}
.footer__networking {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 880px) {
  .footer__networking {
    width: 100%;
  }
  * + .footer__networking {
    margin-top: 1.618rem;
  }
}
.footer__networking .social {
  flex-wrap: wrap;
}
@media only screen and (min-width: 881px) {
  .footer__networking .social {
    justify-content: flex-end;
  }
}
.footer__networking * + social {
  margin-top: 1.618rem;
}
.footer__networking .social__title {
  display: block;
  width: 100%;
  font-size: 0.888888888888889rem;
  font-size: var(--fz-ratio--minus-one);
  color: #555;
}
@media only screen and (min-width: 881px) {
  .footer__networking .social__title {
    text-align: right;
  }
}
@media only screen and (min-width: 681px) {
  .footer__networking .social__title {
    font-size: 0.824742268041237rem;
    font-size: var(--fz-ratio--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .footer__networking .social__title {
    font-size: 0.769230769230769rem;
    font-size: var(--fz-ratio--minus-one);
  }
}
* + .footer__networking .social__list {
  margin-top: 0.4045rem;
}
@media only screen and (max-width: 880px) {
  .footer__networking .social__list {
    flex-wrap: wrap;
  }
}
.footer__networking .social__list_item {
  flex: 0 0 auto;
}
@media only screen and (max-width: 880px) {
  * + .footer__networking .social__list_item--newsletter {
    margin-left: auto;
  }
}
.footer__networking .social__list_item .icon {
  margin-left: 0.3236rem;
}
.footer__networking .social__link:link,
.footer__networking .social__link:visited {
  color: #181818;
}
.footer__networking .social__link:link .icon,
.footer__networking .social__link:visited .icon {
/*
        the below line should be commented out because we have exceeded most browsers'
        will-change budget allowance. we can fix this by only using one or
        two properties as a default and then specifying properties every time
        we call the mixin, but at present removing default properties from this
        mixin causes many of the site's styles to break, and actually commenting
        out the line also causes styles to break.
    */
  transition-property: outline-offset, fill;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  height: 38px;
  width: auto;
  fill: #181818;
}
.footer__networking .social__link:active,
.footer__networking .social__link:focus,
.footer__networking .social__link:hover {
  color: #e52321;
}
.footer__networking .social__link:active .icon,
.footer__networking .social__link:focus .icon,
.footer__networking .social__link:hover .icon {
  fill: #e52321;
}
.global_search {
  position: absolute;
  z-index: auto;
  top: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  height: 6.472rem;
  background-color: #181818;
}
.global_search__inner {
  display: flex;
  margin-right: auto;
  margin-left: auto;
  width: 92%;
  max-width: 1600px;
  align-items: center;
  height: 100%;
  padding: 0.809rem 0;
}
.global_search__form {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
@media only screen and (max-width: 680px) {
  .global_search__form {
    flex-wrap: wrap;
  }
}
.global_search__label {
  flex: 0 0 100%;
  margin-right: 0.539333333333333rem;
  color: #fff;
}
@media only screen and (min-width: 681px) {
  .global_search__label {
    flex: 0 0 auto;
  }
}
.global_search__input {
  flex: 1 1 auto;
  border: none;
  border-bottom: 2px solid #fff;
  background-color: transparent;
  padding: 0.4045rem 0;
  color: #fff;
}
@media only screen and (min-width: 681px) {
  * + .global_search__input {
    margin-left: 0.809rem;
  }
}
.global_search__input::placeholder {
  color: rgba(255,255,255,0.6);
}
.global_search__button {
/*
        the below line should be commented out because we have exceeded most browsers'
        will-change budget allowance. we can fix this by only using one or
        two properties as a default and then specifying properties every time
        we call the mixin, but at present removing default properties from this
        mixin causes many of the site's styles to break, and actually commenting
        out the line also causes styles to break.
    */
  transition-property: outline-offset, opacity;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  flex: 0 0 auto;
  margin-top: auto;
  border-radius: 5px;
  background-color: #fff;
  padding: 0.20225rem 0.809rem;
  color: #555;
}
* + .global_search__button {
  margin-left: 1.618rem;
}
.global_search__button:hover {
  opacity: 0.7;
}
.global_search__close_button {
  display: flex;
  height: 1.618rem;
  width: 1.618rem;
}
* + .global_search__close_button {
  margin-left: 1.618rem;
}
@media only screen and (min-width: 481px) {
  .global_search__close_button {
    height: 3.236rem;
  }
}
@media only screen and (min-width: 481px) {
  .global_search__close_button {
    width: 3.236rem;
  }
}
.global_search__close_button:hover {
  opacity: 0.7;
}
.global_header {
  width: 100%;
  background-color: #f1f3f9;
  color: #fff;
}
.home .global_header {
  background-color: transparent;
}
.global_header__inner {
  display: flex;
  margin-right: auto;
  margin-left: auto;
  width: 92%;
  max-width: 1600px;
  align-items: center;
  justify-content: space-between;
  height: 6.472rem;
  min-height: 6.472rem;
}
@media only screen and (min-width: 681px) and (max-width: 880px) {
  .global_header__inner {
    flex-wrap: wrap;
  }
}
@media only screen and (min-width: 681px) {
  .global_header__inner {
    padding-top: 0.809rem;
  }
}
@media only screen and (min-width: 881px) {
  .global_header__inner {
    padding-top: 0;
  }
}
.global_header__inner > .navigation__list {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media only screen and (min-width: 681px) and (max-width: 880px) {
  .global_header__inner > .navigation__list {
    order: 2;
  }
}
.global_header__inner > .navigation__list:not(:first-child) {
  margin-left: 1.618rem;
}
.global_header__inner > .navigation__list .navigation__item:not(:first-child) {
  margin-left: 0.809rem;
}
.global_header__inner > .navigation__icon--logo,
.global_header__inner > .navigation,
.global_header__inner > .navigation__list {
  z-index: 10;
}
.navigation {
  flex: 0 1 auto;
}
@media only screen and (max-width: 680px) {
  .navigation {
    display: none;
  }
}
@media only screen and (min-width: 681px) and (max-width: 880px) {
  * + .navigation {
    margin-top: 0.809rem;
  }
}
.navigation .navigation__list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: -0.809rem;
}
.navigation .navigation__item {
  flex: 0 0 auto;
  margin-bottom: 0.809rem;
}
.navigation .navigation__item:not(:last-child) {
  margin-right: 1.618rem;
}
.navigation .navigation__link,
.navigation .navigation__link:link,
.navigation .navigation__link:visited {
  box-shadow: inset 0 -1px rgba(85,85,85,0.2);
  color: #555;
}
.navigation .navigation__link:hover {
  box-shadow: inset 0 -0.24em rgba(85,85,85,0.2);
  color: #555;
}
.navigation .navigation__link:active,
.navigation .navigation__link:focus {
  box-shadow: inset 0 -0.14em rgba(85,85,85,0.2);
  color: #555;
}
.navigation .navigation__link--active {
  box-shadow: inset 0 -4px #333;
}
.navigation .navigation__link--active:hover {
  box-shadow: inset 0 -4px #333;
}
.navigation__icon {
  box-shadow: none;
}
.navigation__icon .icon {
  fill: #555;
}
.navigation__icon:active,
.navigation__icon:focus,
.navigation__icon:hover {
  box-shadow: none;
}
.hero .navigation__link,
.navigation_menu .navigation__link {
  box-shadow: none;
}
.hero .navigation__link,
.navigation_menu .navigation__link,
.hero .navigation__link:link,
.navigation_menu .navigation__link:link,
.hero .navigation__link:visited,
.navigation_menu .navigation__link:visited {
  box-shadow: inset 0 -1px rgba(255,255,255,0.2);
  color: #fff;
}
.hero .navigation__link:hover,
.navigation_menu .navigation__link:hover {
  box-shadow: inset 0 -0.24em rgba(255,255,255,0.2);
  color: #fff;
}
.hero .navigation__link:active,
.navigation_menu .navigation__link:active,
.hero .navigation__link:focus,
.navigation_menu .navigation__link:focus {
  box-shadow: inset 0 -0.14em rgba(255,255,255,0.2);
  color: #fff;
}
.hero .navigation__link--active,
.navigation_menu .navigation__link--active {
  box-shadow: inset 0 -4px #fff;
}
.hero .navigation__link--active:hover,
.navigation_menu .navigation__link--active:hover {
  box-shadow: inset 0 -4px #fff;
}
.hero .navigation__icon,
.navigation_menu .navigation__icon {
  display: flex;
  box-shadow: none;
}
.hero .navigation__icon .icon,
.navigation_menu .navigation__icon .icon {
  fill: #fff;
}
.hero .navigation__icon:active,
.navigation_menu .navigation__icon:active,
.hero .navigation__icon:focus,
.navigation_menu .navigation__icon:focus,
.hero .navigation__icon:hover,
.navigation_menu .navigation__icon:hover {
  box-shadow: none;
}
.hero .navigation__icon--logo,
.navigation_menu .navigation__icon--logo {
  box-shadow: inset 0 0px #fff;
}
.hero .navigation__icon--logo .icon__logo,
.navigation_menu .navigation__icon--logo .icon__logo {
  fill: #fff;
}
.hero .navigation__icon--logo:hover,
.navigation_menu .navigation__icon--logo:hover {
  box-shadow: inset 0 -4px #fff;
}
.hero .navigation__icon--logo:hover .icon__logo,
.navigation_menu .navigation__icon--logo:hover .icon__logo {
  fill: #fff;
}
.navigation__icon--logo,
.logo {
/*
        the below line should be commented out because we have exceeded most browsers'
        will-change budget allowance. we can fix this by only using one or
        two properties as a default and then specifying properties every time
        we call the mixin, but at present removing default properties from this
        mixin causes many of the site's styles to break, and actually commenting
        out the line also causes styles to break.
    */
  transition-property: outline-offset, box-shadow, opacity;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  overflow: hidden;
  display: block;
  margin-bottom: -8px;
  box-shadow: inset 0 0 #555;
  padding-bottom: 8px;
}
@media only screen and (min-width: 881px) {
  .navigation__icon--logo,
  .logo {
    margin-right: 1.618rem;
  }
}
.navigation__icon--logo:hover,
.logo:hover {
  box-shadow: inset 0 -4px #555;
  opacity: 1;
}
.navigation__icon--logo:hover .icon__logo,
.logo:hover .icon__logo {
  fill: #555;
}
.navigation__icon--logo .icon__logo,
.logo .icon__logo {
/*
        the below line should be commented out because we have exceeded most browsers'
        will-change budget allowance. we can fix this by only using one or
        two properties as a default and then specifying properties every time
        we call the mixin, but at present removing default properties from this
        mixin causes many of the site's styles to break, and actually commenting
        out the line also causes styles to break.
    */
  transition-property: outline-offset, fill;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  fill: #555;
}
.navigation__icon--logo {
  flex: 0 0 auto;
}
.navigation__icon--logo svg {
  height: 2.9rem;
  width: 6rem;
}
.navigation__link.navigation__icon {
  display: flex;
}
.global_search_is_visible .home .navigation__link.navigation__icon--hamburger svg {
  fill: rgba(255,255,255,0.2);
}
.global_search_is_visible .navigation__link.navigation__icon--hamburger {
  pointer-events: none;
}
.global_search_is_visible .navigation__link.navigation__icon--hamburger svg {
  fill: rgba(85,85,85,0.2);
}
.navigation_menu {
/*
        the below line should be commented out because we have exceeded most browsers'
        will-change budget allowance. we can fix this by only using one or
        two properties as a default and then specifying properties every time
        we call the mixin, but at present removing default properties from this
        mixin causes many of the site's styles to break, and actually commenting
        out the line also causes styles to break.
    */
  transition-property: outline-offset, transform;
  transition-duration: 350ms;
  transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
  pointer-events: none;
  z-index: 100;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100vh;
  min-height: 100vh;
  width: 100%;
  max-width: 100%;
  background-color: #181818;
}
.navigation_menu.navigation--show {
  animation: fade-in 0.28s;
  pointer-events: auto;
  display: flex;
  flex-direction: column;
}
.navigation_menu.will_fade_out {
  animation: fade-out 0.28s;
}
.global_search_is_visible .navigation_menu {
  margin-top: 4.854rem;
}
.navigation_menu__upper {
  width: 100%;
}
.navigation_menu__lower {
  display: flex;
  margin-right: auto;
  margin-left: auto;
  width: 92%;
  max-width: 1600px;
  flex-direction: column;
  margin-top: 1.618rem;
  height: 100%;
}
@media only screen and (min-width: 681px) {
  .navigation_menu__lower {
    margin-top: 3.236rem;
  }
}
@media only screen and (min-width: 1081px) {
  .navigation_menu__lower {
    margin-top: 4.854rem;
  }
}
.navigation_menu__lower .social {
  justify-content: space-between;
  align-items: center;
  margin-top: 1.618rem;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: 1600px;
  color: #fff;
}
.navigation_menu__list {
  display: flex;
  margin-right: auto;
  margin-left: auto;
  width: 1600px;
  max-width: 100%;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
}
.navigation_menu__list--primary {
  padding-bottom: 1.618rem;
  color: #fff;
  border-bottom: 2px solid #f1912e;
}
.navigation_menu__list--secondary {
  padding-top: 1.618rem;
  padding-bottom: 1.618rem;
  color: #fff;
  border-bottom: 2px solid #7fcce0;
}
.navigation_menu__list_item {
  display: block;
  margin: 0;
}
@media only screen and (min-width: 1081px) {
  .navigation_menu__list_item {
    flex: 0 0 33.333%;
  }
}
.navigation__list_item--primary {
  padding: 1.077588rem 0;
  padding-right: 1.618rem;
  line-height: 1.2;
  font-size: 1.265625rem;
  font-size: var(--fz-ratio--two);
}
@media only screen and (min-width: 1081px) {
  .navigation__list_item--primary {
    padding-right: 2.427rem;
  }
}
@media only screen and (min-width: 681px) {
  .navigation__list_item--primary {
    font-size: 1.47015625rem;
    font-size: var(--fz-ratio--two);
  }
}
@media only screen and (min-width: 1281px) {
  .navigation__list_item--primary {
    font-size: 1.69rem;
    font-size: var(--fz-ratio--two);
  }
}
.navigation__list_item--secondary {
  padding: 0.4045rem 0;
  padding-right: 1.618rem;
  line-height: 1.4;
  font-size: 1rem;
}
@media only screen and (min-width: 1081px) {
  .navigation__list_item--secondary {
    padding-right: 2.427rem;
  }
}
.navigation_menu__list-anchor {
  display: inline;
}
.navigation_menu__list-anchor,
.navigation_menu__list-anchor:link,
.navigation_menu__list-anchor:visited {
  box-shadow: inset 0 -1px rgba(255,255,255,0.2);
  color: #fff;
}
.navigation_menu__list-anchor:hover {
  box-shadow: inset 0 -0.24em rgba(255,255,255,0.2);
  color: #fff;
}
.navigation_menu__list-anchor:active,
.navigation_menu__list-anchor:focus {
  box-shadow: inset 0 -0.14em rgba(255,255,255,0.2);
  color: #fff;
}
.navigation_menu__list--primary,
.navigation_menu__list--secondary,
.navigation_menu__lower .social {
/*
        the below line should be commented out because we have exceeded most browsers'
        will-change budget allowance. we can fix this by only using one or
        two properties as a default and then specifying properties every time
        we call the mixin, but at present removing default properties from this
        mixin causes many of the site's styles to break, and actually commenting
        out the line also causes styles to break.
    */
  transition-property: outline-offset, background-color, box-shadow, color, opacity;
  transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  transform: translateY(25px);
  opacity: 0;
}
.navigation--show .navigation_menu__list--primary {
  transition-delay: 400ms;
}
.navigation--show .navigation_menu__list--secondary {
  transition-delay: 500ms;
}
.navigation--show .navigation_menu__lower .social {
  transition-delay: 600ms;
}
.navigation--show .navigation_menu__list--primary,
.navigation--show .navigation_menu__list--secondary,
.navigation--show .navigation_menu__lower .social {
  transform: translateY(0);
  opacity: 1;
}
.accordion {
  display: block;
  width: 100%;
}
* + .accordion {
  margin-top: 3.236rem;
}
.accordion ::selection {
  background-color: transparent;
  color: inherit;
}
.accordion__list {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0 auto;
  width: 92%;
  max-width: 1180px;
}
.accordion__item {
  flex: 0 0 auto;
  overflow: hidden;
  display: block;
  box-shadow: 0 12px 17px -5px rgba(31,31,31,0.2), 0 35px 110px 0px rgba(31,31,31,0.1);
  border-radius: 5px;
  width: 100%;
}
* + .accordion__item {
  margin-top: 1.618rem;
}
.accordion__details {
  overflow: hidden;
  background-color: #fff;
}
.accordion__summary {
/*
        the below line should be commented out because we have exceeded most browsers'
        will-change budget allowance. we can fix this by only using one or
        two properties as a default and then specifying properties every time
        we call the mixin, but at present removing default properties from this
        mixin causes many of the site's styles to break, and actually commenting
        out the line also causes styles to break.
    */
  transition-property: outline-offset, background-color;
  transition-duration: 0.28s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #e5e9f1;
  padding: 0.809rem 1.618rem;
}
.accordion__summary:focus,
.accordion__summary:hover,
.accordion__details[open] .accordion__summary {
  background-color: #333;
  color: #fff;
}
.accordion__summary:focus .accordion__summary__title,
.accordion__summary:hover .accordion__summary__title,
.accordion__details[open] .accordion__summary .accordion__summary__title {
  color: #fff;
}
.accordion__summary:focus .accordion__summary__icon svg,
.accordion__summary:hover .accordion__summary__icon svg,
.accordion__details[open] .accordion__summary .accordion__summary__icon svg {
  fill: #fff;
}
.accordion__summary::-webkit-details-marker {
  display: none;
}
.accordion__title {
/*
        the below line should be commented out because we have exceeded most browsers'
        will-change budget allowance. we can fix this by only using one or
        two properties as a default and then specifying properties every time
        we call the mixin, but at present removing default properties from this
        mixin causes many of the site's styles to break, and actually commenting
        out the line also causes styles to break.
    */
  transition-property: outline-offset, color;
  transition-duration: 0.28s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  flex: 1;
  font-weight: 700;
  color: #555;
}
.accordion__summary__icon {
  flex: 0 0 auto;
  margin-top: 0.20225rem;
}
* + .accordion__summary__icon {
  margin-left: 1.618rem;
}
.accordion__summary__icon svg {
/*
        the below line should be commented out because we have exceeded most browsers'
        will-change budget allowance. we can fix this by only using one or
        two properties as a default and then specifying properties every time
        we call the mixin, but at present removing default properties from this
        mixin causes many of the site's styles to break, and actually commenting
        out the line also causes styles to break.
    */
  transition-property: outline-offset, transform;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  transform-origin: 50%;
  fill: #555;
}
.accordion__details[open] .accordion__summary__icon svg {
  transform: rotate(45deg);
  fill: #fff;
}
.accordion__content {
  background-color: #fff;
  padding: 1.618rem;
}
.alerts {
  flex: 0 0 auto;
  display: block;
  width: 100%;
}
* + .alerts {
  margin-top: 3.236rem;
}
.alerts__list {
  display: block;
  margin: 0 auto;
  width: 92%;
  max-width: 1600px;
}
.alerts__item {
  box-shadow: 0 1px 0.06rem rgba(51,51,51,0.08);
  border-radius: 5px;
  border-left: 5px solid;
  padding: 1.618rem 4vw;
}
* + .alerts__item {
  margin-top: 1.618rem;
}
@media only screen and (min-width: 481px) {
  .alerts__item {
    padding: 1.618rem;
  }
}
.alerts__item--error {
  background-color: #fdeeeb;
  border-left-color: #ee5432;
}
.alerts__item--success {
  background-color: #eafaef;
  border-left-color: #2fd060;
}
.block_quote {
  width: 100%;
  max-width: none;
  background-color: #e5e9f1;
}
* + .block_quote {
  margin-top: 3.236rem;
}
.block_quote__inner {
  margin: 0 auto;
  width: 92%;
  max-width: 1180px;
  padding: 3.236rem 0;
}
.block_quote__quote {
  font-size: 1.423828125rem;
  font-size: var(--fz-ratio--three);
  line-height: 1.4;
}
@media only screen and (min-width: 681px) {
  .block_quote__quote {
    font-size: 1.782564453125rem;
    font-size: var(--fz-ratio--three);
  }
}
@media only screen and (min-width: 1281px) {
  .block_quote__quote {
    font-size: 2.197000000000001rem;
    font-size: var(--fz-ratio--three);
  }
}
.block_quote__quote::before,
.block_quote__quote::after {
  color: #555;
}
.block_quote__quote::before {
  content: open-quote;
}
.block_quote__quote::after {
  content: close-quote;
}
.block_quote__citation {
  display: block;
  font-style: italic;
}
* + .block_quote__citation {
  margin-top: 0.539333333333333rem;
}
.block_quote__citation::before {
  content: "–";
  display: inline-block;
  margin-right: 0.269666666666667rem;
}
.breadcrumbs {
  z-index: 1;
  position: relative;
  margin: 0 auto 0.4045rem;
  width: 92%;
  max-width: 1600px;
  background-color: transparent;
  font-size: 0.888888888888889rem;
  font-size: var(--fz-ratio--minus-one);
}
@media only screen and (min-width: 681px) {
  .breadcrumbs {
    font-size: 0.824742268041237rem;
    font-size: var(--fz-ratio--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .breadcrumbs {
    font-size: 0.769230769230769rem;
    font-size: var(--fz-ratio--minus-one);
  }
}
.breadcrumbs__list {
  display: flex;
  list-style: none;
  padding-left: 0;
  margin: 0;
}
.breadcrumbs__item {
  display: flex;
  align-items: center;
  margin-right: 0.269666666666667rem;
}
.breadcrumbs__arrow,
.breadcrumbs__arrow--down {
  stroke: rgba(119,119,119,0.6);
  stroke-width: 2;
  transform: translateY(3px);
}
.breadcrumbs__arrow {
  margin-left: 8px;
}
.breadcrumbs__arrow--down {
  transform: translateY(2.5px);
}
.breadcrumbs__anchor,
.breadcrumbs__anchor:visited {
  box-shadow: inset 0 -1px rgba(85,85,85,0.3);
  color: #555;
}
.breadcrumbs__anchor:active,
.breadcrumbs__anchor:focus,
.breadcrumbs__anchor:hover {
  box-shadow: inset 0 -0.2em rgba(85,85,85,0.6);
  color: #333;
}
.button_container {
  display: block;
  margin-right: auto;
  margin-left: auto;
  width: 92%;
  max-width: 880px;
}
.button-strip {
  width: 100%;
  display: flex;
  justify-content: center;
}
* + .button-strip {
  margin-top: 3.236rem;
}
.button-strip__inner {
  display: inline-block;
  margin-right: auto;
  margin-left: auto;
  background-color: #e5e9f1;
  padding: 1.618rem;
}
.button-strip__inner--single {
  background-color: transparent;
  padding: 0;
}
.button-strip__inner .button {
  margin-left: 1.618rem;
  margin-top: 0;
}
.button-strip__inner .button:first-child {
  margin-left: 0;
}
.button,
.filter_form__reset,
.filter_form__submit,
#mc_embed_signup__reset,
#mc_embed_signup__submit,
#mc_embed_signup .button,
.contact__form__submit {
  padding: 0.809rem 1.2135rem;
}
* + .button,
* + .filter_form__reset,
* + .filter_form__submit,
* + #mc_embed_signup__reset,
* + #mc_embed_signup__submit,
* + #mc_embed_signup .button,
* + .contact__form__submit {
  margin-top: 1.618rem;
}
.button,
.filter_form__reset,
.filter_form__submit,
#mc_embed_signup__reset,
#mc_embed_signup__submit,
#mc_embed_signup .button,
.contact__form__submit,
.button:link,
.filter_form__reset:link,
.filter_form__submit:link,
#mc_embed_signup__reset:link,
#mc_embed_signup__submit:link,
#mc_embed_signup .button:link,
.contact__form__submit:link,
.button:visited,
.filter_form__reset:visited,
.filter_form__submit:visited,
#mc_embed_signup__reset:visited,
#mc_embed_signup__submit:visited,
#mc_embed_signup .button:visited,
.contact__form__submit:visited {
  display: inline-block;
  box-shadow: 0 0 0 2px transparent;
  border-radius: 5px;
  border: 0;
  background-color: #e8eaee;
  text-align: center;
  font-weight: 400;
  color: #555;
}
.button .icon,
.filter_form__reset .icon,
.filter_form__submit .icon,
#mc_embed_signup__reset .icon,
#mc_embed_signup__submit .icon,
#mc_embed_signup .button .icon,
.contact__form__submit .icon,
.button:link .icon,
.filter_form__reset:link .icon,
.filter_form__submit:link .icon,
#mc_embed_signup__reset:link .icon,
#mc_embed_signup__submit:link .icon,
#mc_embed_signup .button:link .icon,
.contact__form__submit:link .icon,
.button:visited .icon,
.filter_form__reset:visited .icon,
.filter_form__submit:visited .icon,
#mc_embed_signup__reset:visited .icon,
#mc_embed_signup__submit:visited .icon,
#mc_embed_signup .button:visited .icon,
.contact__form__submit:visited .icon {
  flex: 0 0 auto;
  margin-left: 0.809rem;
  height: 32px;
  width: 14px;
}
.button:active,
.filter_form__reset:active,
.filter_form__submit:active,
#mc_embed_signup__reset:active,
#mc_embed_signup__submit:active,
#mc_embed_signup .button:active,
.contact__form__submit:active,
.button:focus,
.filter_form__reset:focus,
.filter_form__submit:focus,
#mc_embed_signup__reset:focus,
#mc_embed_signup__submit:focus,
#mc_embed_signup .button:focus,
.contact__form__submit:focus,
.button:hover,
.filter_form__reset:hover,
.filter_form__submit:hover,
#mc_embed_signup__reset:hover,
#mc_embed_signup__submit:hover,
#mc_embed_signup .button:hover,
.contact__form__submit:hover {
  cursor: pointer;
  background-color: #d3d6de;
  color: #333;
}
.button--small,
.filter_form__reset--small,
.filter_form__submit--small,
#mc_embed_signup__reset--small,
#mc_embed_signup__submit--small,
#mc_embed_signup .button--small,
.contact__form__submit--small {
  text-transform: uppercase;
  padding: 0.4045rem 0.60675rem;
  font-size: 0.790123456790123rem;
  font-size: var(--fz-ratio--minus-two);
}
@media only screen and (min-width: 681px) {
  .button--small,
  .filter_form__reset--small,
  .filter_form__submit--small,
  #mc_embed_signup__reset--small,
  #mc_embed_signup__submit--small,
  #mc_embed_signup .button--small,
  .contact__form__submit--small {
    font-size: 0.680199808693804rem;
    font-size: var(--fz-ratio--minus-two);
  }
}
@media only screen and (min-width: 1281px) {
  .button--small,
  .filter_form__reset--small,
  .filter_form__submit--small,
  #mc_embed_signup__reset--small,
  #mc_embed_signup__submit--small,
  #mc_embed_signup .button--small,
  .contact__form__submit--small {
    font-size: 0.591715976331361rem;
    font-size: var(--fz-ratio--minus-two);
  }
}
.button--large,
.filter_form__reset--large,
.filter_form__submit--large,
#mc_embed_signup__reset--large,
#mc_embed_signup__submit--large,
#mc_embed_signup .button--large,
.contact__form__submit--large,
#mc_embed_signup .button {
  padding: 1.2135rem 1.82025rem;
  font-size: 0.888888888888889rem;
  font-size: var(--fz-ratio--minus-one);
}
@media only screen and (min-width: 681px) {
  .button--large,
  .filter_form__reset--large,
  .filter_form__submit--large,
  #mc_embed_signup__reset--large,
  #mc_embed_signup__submit--large,
  #mc_embed_signup .button--large,
  .contact__form__submit--large,
  #mc_embed_signup .button {
    font-size: 0.824742268041237rem;
    font-size: var(--fz-ratio--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .button--large,
  .filter_form__reset--large,
  .filter_form__submit--large,
  #mc_embed_signup__reset--large,
  #mc_embed_signup__submit--large,
  #mc_embed_signup .button--large,
  .contact__form__submit--large,
  #mc_embed_signup .button {
    font-size: 0.769230769230769rem;
    font-size: var(--fz-ratio--minus-one);
  }
}
.button__inner,
.filter_form__reset__inner,
.filter_form__submit__inner,
#mc_embed_signup__reset__inner,
#mc_embed_signup__submit__inner,
#mc_embed_signup .button__inner,
.contact__form__submit__inner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.button--light,
.filter_form__reset--light,
.filter_form__submit--light,
#mc_embed_signup__reset--light,
#mc_embed_signup__submit--light,
#mc_embed_signup .button--light,
.contact__form__submit--light,
.filter_form__reset,
.filter_form__submit,
#mc_embed_signup__reset,
#mc_embed_signup__submit,
#mc_embed_signup .button,
.contact__form__submit,
.button--light:link,
.filter_form__reset--light:link,
.filter_form__submit--light:link,
#mc_embed_signup__reset--light:link,
#mc_embed_signup__submit--light:link,
#mc_embed_signup .button--light:link,
.contact__form__submit--light:link,
.filter_form__reset:link,
.filter_form__submit:link,
#mc_embed_signup__reset:link,
#mc_embed_signup__submit:link,
#mc_embed_signup .button:link,
.contact__form__submit:link,
.button--light:visited,
.filter_form__reset--light:visited,
.filter_form__submit--light:visited,
#mc_embed_signup__reset--light:visited,
#mc_embed_signup__submit--light:visited,
#mc_embed_signup .button--light:visited,
.contact__form__submit--light:visited,
.filter_form__reset:visited,
.filter_form__submit:visited,
#mc_embed_signup__reset:visited,
#mc_embed_signup__submit:visited,
#mc_embed_signup .button:visited,
.contact__form__submit:visited {
  box-shadow: 0px 6px 12px -2px rgba(51,51,51,0.08), 0px 2px 24px rgba(51,51,51,0.02);
  background-color: #fff;
  color: #333;
}
.button--light .icon,
.filter_form__reset--light .icon,
.filter_form__submit--light .icon,
#mc_embed_signup__reset--light .icon,
#mc_embed_signup__submit--light .icon,
#mc_embed_signup .button--light .icon,
.contact__form__submit--light .icon,
.filter_form__reset .icon,
.filter_form__submit .icon,
#mc_embed_signup__reset .icon,
#mc_embed_signup__submit .icon,
#mc_embed_signup .button .icon,
.contact__form__submit .icon,
.button--light:link .icon,
.filter_form__reset--light:link .icon,
.filter_form__submit--light:link .icon,
#mc_embed_signup__reset--light:link .icon,
#mc_embed_signup__submit--light:link .icon,
#mc_embed_signup .button--light:link .icon,
.contact__form__submit--light:link .icon,
.filter_form__reset:link .icon,
.filter_form__submit:link .icon,
#mc_embed_signup__reset:link .icon,
#mc_embed_signup__submit:link .icon,
#mc_embed_signup .button:link .icon,
.contact__form__submit:link .icon,
.button--light:visited .icon,
.filter_form__reset--light:visited .icon,
.filter_form__submit--light:visited .icon,
#mc_embed_signup__reset--light:visited .icon,
#mc_embed_signup__submit--light:visited .icon,
#mc_embed_signup .button--light:visited .icon,
.contact__form__submit--light:visited .icon,
.filter_form__reset:visited .icon,
.filter_form__submit:visited .icon,
#mc_embed_signup__reset:visited .icon,
#mc_embed_signup__submit:visited .icon,
#mc_embed_signup .button:visited .icon,
.contact__form__submit:visited .icon {
  fill: #333;
}
.button--light:active,
.filter_form__reset--light:active,
.filter_form__submit--light:active,
#mc_embed_signup__reset--light:active,
#mc_embed_signup__submit--light:active,
#mc_embed_signup .button--light:active,
.contact__form__submit--light:active,
.filter_form__reset:active,
.filter_form__submit:active,
#mc_embed_signup__reset:active,
#mc_embed_signup__submit:active,
#mc_embed_signup .button:active,
.contact__form__submit:active,
.button--light:focus,
.filter_form__reset--light:focus,
.filter_form__submit--light:focus,
#mc_embed_signup__reset--light:focus,
#mc_embed_signup__submit--light:focus,
#mc_embed_signup .button--light:focus,
.contact__form__submit--light:focus,
.filter_form__reset:focus,
.filter_form__submit:focus,
#mc_embed_signup__reset:focus,
#mc_embed_signup__submit:focus,
#mc_embed_signup .button:focus,
.contact__form__submit:focus,
.button--light:hover,
.filter_form__reset--light:hover,
.filter_form__submit--light:hover,
#mc_embed_signup__reset--light:hover,
#mc_embed_signup__submit--light:hover,
#mc_embed_signup .button--light:hover,
.contact__form__submit--light:hover,
.filter_form__reset:hover,
.filter_form__submit:hover,
#mc_embed_signup__reset:hover,
#mc_embed_signup__submit:hover,
#mc_embed_signup .button:hover,
.contact__form__submit:hover {
  box-shadow: 0px 1px 1px rgba(31,31,31,0.3), 0px 1px 2px rgba(31,31,31,0.2);
  background-color: #fff;
}
.button--dark,
.filter_form__reset--dark,
.filter_form__submit--dark,
#mc_embed_signup__reset--dark,
#mc_embed_signup__submit--dark,
#mc_embed_signup .button--dark,
.contact__form__submit--dark {
  margin-right: auto;
  margin-left: auto;
}
.button--dark,
.filter_form__reset--dark,
.filter_form__submit--dark,
#mc_embed_signup__reset--dark,
#mc_embed_signup__submit--dark,
#mc_embed_signup .button--dark,
.contact__form__submit--dark,
.button--dark:link,
.filter_form__reset--dark:link,
.filter_form__submit--dark:link,
#mc_embed_signup__reset--dark:link,
#mc_embed_signup__submit--dark:link,
#mc_embed_signup .button--dark:link,
.contact__form__submit--dark:link,
.button--dark:visited,
.filter_form__reset--dark:visited,
.filter_form__submit--dark:visited,
#mc_embed_signup__reset--dark:visited,
#mc_embed_signup__submit--dark:visited,
#mc_embed_signup .button--dark:visited,
.contact__form__submit--dark:visited {
  background-color: #333;
  color: #fff;
}
.button--dark .icon,
.filter_form__reset--dark .icon,
.filter_form__submit--dark .icon,
#mc_embed_signup__reset--dark .icon,
#mc_embed_signup__submit--dark .icon,
#mc_embed_signup .button--dark .icon,
.contact__form__submit--dark .icon,
.button--dark:link .icon,
.filter_form__reset--dark:link .icon,
.filter_form__submit--dark:link .icon,
#mc_embed_signup__reset--dark:link .icon,
#mc_embed_signup__submit--dark:link .icon,
#mc_embed_signup .button--dark:link .icon,
.contact__form__submit--dark:link .icon,
.button--dark:visited .icon,
.filter_form__reset--dark:visited .icon,
.filter_form__submit--dark:visited .icon,
#mc_embed_signup__reset--dark:visited .icon,
#mc_embed_signup__submit--dark:visited .icon,
#mc_embed_signup .button--dark:visited .icon,
.contact__form__submit--dark:visited .icon {
  fill: #fff;
}
.button--dark:active,
.filter_form__reset--dark:active,
.filter_form__submit--dark:active,
#mc_embed_signup__reset--dark:active,
#mc_embed_signup__submit--dark:active,
#mc_embed_signup .button--dark:active,
.contact__form__submit--dark:active,
.button--dark:focus,
.filter_form__reset--dark:focus,
.filter_form__submit--dark:focus,
#mc_embed_signup__reset--dark:focus,
#mc_embed_signup__submit--dark:focus,
#mc_embed_signup .button--dark:focus,
.contact__form__submit--dark:focus,
.button--dark:hover,
.filter_form__reset--dark:hover,
.filter_form__submit--dark:hover,
#mc_embed_signup__reset--dark:hover,
#mc_embed_signup__submit--dark:hover,
#mc_embed_signup .button--dark:hover,
.contact__form__submit--dark:hover {
  background-color: #000;
}
.button--blue,
.filter_form__reset--blue,
.filter_form__submit--blue,
#mc_embed_signup__reset--blue,
#mc_embed_signup__submit--blue,
#mc_embed_signup .button--blue,
.contact__form__submit--blue {
  margin-right: auto;
  margin-left: auto;
}
.button--blue,
.filter_form__reset--blue,
.filter_form__submit--blue,
#mc_embed_signup__reset--blue,
#mc_embed_signup__submit--blue,
#mc_embed_signup .button--blue,
.contact__form__submit--blue,
.button--blue:link,
.filter_form__reset--blue:link,
.filter_form__submit--blue:link,
#mc_embed_signup__reset--blue:link,
#mc_embed_signup__submit--blue:link,
#mc_embed_signup .button--blue:link,
.contact__form__submit--blue:link,
.button--blue:visited,
.filter_form__reset--blue:visited,
.filter_form__submit--blue:visited,
#mc_embed_signup__reset--blue:visited,
#mc_embed_signup__submit--blue:visited,
#mc_embed_signup .button--blue:visited,
.contact__form__submit--blue:visited {
  background-color: #509ece;
  color: #fff;
}
.button--blue .icon,
.filter_form__reset--blue .icon,
.filter_form__submit--blue .icon,
#mc_embed_signup__reset--blue .icon,
#mc_embed_signup__submit--blue .icon,
#mc_embed_signup .button--blue .icon,
.contact__form__submit--blue .icon,
.button--blue:link .icon,
.filter_form__reset--blue:link .icon,
.filter_form__submit--blue:link .icon,
#mc_embed_signup__reset--blue:link .icon,
#mc_embed_signup__submit--blue:link .icon,
#mc_embed_signup .button--blue:link .icon,
.contact__form__submit--blue:link .icon,
.button--blue:visited .icon,
.filter_form__reset--blue:visited .icon,
.filter_form__submit--blue:visited .icon,
#mc_embed_signup__reset--blue:visited .icon,
#mc_embed_signup__submit--blue:visited .icon,
#mc_embed_signup .button--blue:visited .icon,
.contact__form__submit--blue:visited .icon {
  fill: #fff;
}
.button--blue:active,
.filter_form__reset--blue:active,
.filter_form__submit--blue:active,
#mc_embed_signup__reset--blue:active,
#mc_embed_signup__submit--blue:active,
#mc_embed_signup .button--blue:active,
.contact__form__submit--blue:active,
.button--blue:focus,
.filter_form__reset--blue:focus,
.filter_form__submit--blue:focus,
#mc_embed_signup__reset--blue:focus,
#mc_embed_signup__submit--blue:focus,
#mc_embed_signup .button--blue:focus,
.contact__form__submit--blue:focus,
.button--blue:hover,
.filter_form__reset--blue:hover,
.filter_form__submit--blue:hover,
#mc_embed_signup__reset--blue:hover,
#mc_embed_signup__submit--blue:hover,
#mc_embed_signup .button--blue:hover,
.contact__form__submit--blue:hover {
  background-color: #317eae;
}
.cards {
  margin-top: 0;
  padding: 0;
}
.card {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 0 12px 17px -5px rgba(31,31,31,0.2), 0 35px 110px 0px rgba(31,31,31,0.1);
}
.card img {
  width: 100%;
}
.card__upper {
  flex: 0 0 auto;
  padding: 0.809rem 1.077588rem 1.077588rem;
  background-color: #eff0f2;
  color: #fff;
}
.card__first {
  flex: 1 1 auto;
}
.card__first:hover {
  opacity: 0.6;
}
.card__second {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #fff;
}
.card__heading {
  text-align: left;
  font-weight: 700;
  font-family: 'roboto-condensed', sans-serif;
  font-size: 1.125rem;
  font-size: var(--fz-ratio--one);
  color: #555;
}
@media only screen and (min-width: 681px) {
  .card__heading {
    font-size: 1.2125rem;
    font-size: var(--fz-ratio--one);
  }
}
@media only screen and (min-width: 1281px) {
  .card__heading {
    font-size: 1.3rem;
    font-size: var(--fz-ratio--one);
  }
}
.card__heading a,
.card__heading a:visited {
  color: #555;
}
.card__heading a:active,
.card__heading a:focus,
.card__heading a:hover {
  color: #181818;
}
.card__lower {
  background-color: #fff;
  padding: 0.809rem 1.077588rem 1.077588rem;
}
.card__centre_facilities {
  display: flex;
  flex-direction: column;
  margin-top: 1.077588rem;
  border-top: 2px solid rgba(119,119,119,0.1);
  padding-top: 0.809rem;
}
.card__centre_facilities_heading {
  font-size: 0.888888888888889rem;
  font-size: var(--fz-ratio--minus-one);
  color: #333;
}
@media only screen and (min-width: 681px) {
  .card__centre_facilities_heading {
    font-size: 0.824742268041237rem;
    font-size: var(--fz-ratio--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .card__centre_facilities_heading {
    font-size: 0.769230769230769rem;
    font-size: var(--fz-ratio--minus-one);
  }
}
.card__centre_facilities_list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
* + .card__centre_facilities_list {
  margin-top: 0.809rem;
}
.card__centre_facilities_item {
  flex: 0 0 49%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media only screen and (min-width: 681px) {
  .card__centre_facilities_item {
    flex: 0 0 32%;
  }
}
@media only screen and (max-width: 680px) {
  .card__centre_facilities_item:nth-child(even) {
    margin-left: 2%;
  }
}
@media only screen and (min-width: 681px) {
  .card__centre_facilities_item:nth-child(3n+2),
  .card__centre_facilities_item:nth-child(3n+3) {
    margin-left: 2%;
  }
}
@media only screen and (max-width: 680px) {
  .card__centre_facilities_item:nth-child(n+3) {
    margin-top: 0.809rem;
  }
}
@media only screen and (min-width: 681px) {
  .card__centre_facilities_item:nth-child(n+4) {
    margin-top: 0.809rem;
  }
}
.card__centre_facilities_item:last-child {
  margin-right: auto;
}
.card__centre_facilities_label {
  margin: 0;
  margin-top: 0.269666666666667rem;
  text-transform: uppercase;
  font-size: 0.790123456790123rem;
  font-size: var(--fz-ratio--minus-two);
  line-height: 1;
  color: #555;
}
@media only screen and (min-width: 681px) {
  .card__centre_facilities_label {
    font-size: 0.680199808693804rem;
    font-size: var(--fz-ratio--minus-two);
  }
}
@media only screen and (min-width: 1281px) {
  .card__centre_facilities_label {
    font-size: 0.591715976331361rem;
    font-size: var(--fz-ratio--minus-two);
  }
}
.card__centre_facilities_label .icon {
  width: 52px;
  height: auto;
}
.card__centre_facilities_label .icon:first-child {
  margin-left: 0;
}
.card__centre_facilities_label .button {
  margin-top: 0.809rem;
}
.card__information_list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.card__information_item {
  flex: 0 0 49%;
  margin-top: 0;
  line-height: 1.4;
  font-size: 0.790123456790123rem;
  font-size: var(--fz-ratio--minus-two);
}
@media only screen and (min-width: 681px) {
  .card__information_item {
    font-size: 0.680199808693804rem;
    font-size: var(--fz-ratio--minus-two);
  }
}
@media only screen and (min-width: 1281px) {
  .card__information_item {
    font-size: 0.591715976331361rem;
    font-size: var(--fz-ratio--minus-two);
  }
}
.card__information_item:nth-child(even) {
  margin-left: 2%;
}
.card__information_item:nth-child(n+3) {
  margin-top: 0.809rem;
}
.card__information_item:last-child {
  margin-right: auto;
}
.card__information_item .button--small {
  padding: 0.4045rem 0.539333333333333rem;
  font-size: 0.790123456790123rem;
  font-size: var(--fz-ratio--minus-two);
}
* + .card__information_item .button--small {
  margin-top: 0.4045rem;
}
@media only screen and (min-width: 681px) {
  .card__information_item .button--small {
    font-size: 0.680199808693804rem;
    font-size: var(--fz-ratio--minus-two);
  }
}
@media only screen and (min-width: 1281px) {
  .card__information_item .button--small {
    font-size: 0.591715976331361rem;
    font-size: var(--fz-ratio--minus-two);
  }
}
.card__information_item a:not(.button),
.card__information_item a:not(.button):visited,
.card__information_item a:not(.button):link,
.card__information_item a:not(.button):visited:link,
.card__information_item a:not(.button):visited:visited {
  box-shadow: inset 0 -1px rgba(51,51,51,0.2);
  color: #333;
}
.card__information_item a:not(.button):hover,
.card__information_item a:not(.button):visited:hover {
  box-shadow: inset 0 -0.24em rgba(102,102,102,0.2);
  color: #333;
}
.card__information_item a:not(.button):active,
.card__information_item a:not(.button):visited:active,
.card__information_item a:not(.button):focus,
.card__information_item a:not(.button):visited:focus {
  box-shadow: inset 0 -0.14em rgba(24,24,24,0.2);
  color: #181818;
}
.card__information_title,
.card__information_opening_times {
  display: block;
}
.card__information_title {
  margin-bottom: 0.1618rem;
  font-weight: 700;
}
.card__meta {
  margin-top: 0.809rem;
  text-align: left;
  font-weight: 700;
  font-size: 0.790123456790123rem;
  font-size: var(--fz-ratio--minus-two);
}
@media only screen and (min-width: 681px) {
  .card__meta {
    font-size: 0.680199808693804rem;
    font-size: var(--fz-ratio--minus-two);
  }
}
@media only screen and (min-width: 1281px) {
  .card__meta {
    font-size: 0.591715976331361rem;
    font-size: var(--fz-ratio--minus-two);
  }
}
.card__meta_list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
* + .card__meta_list {
  margin-top: 0.539333333333333rem;
}
.card__meta_item {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-right: 1.618rem;
  text-transform: uppercase;
  font-weight: 700;
}
.card__meta_value {
  font-weight: 400;
}
* + .card__meta_value {
  margin-left: 0.20225rem;
}
.card__meta_value--anchor,
.card__meta_value--anchor:visited {
  box-shadow: inset 0 -1px rgba(85,85,85,0.3);
  color: #555;
}
.card__meta_value--anchor:active,
.card__meta_value--anchor:focus,
.card__meta_value--anchor:hover {
  box-shadow: inset 0 -0.2em rgba(85,85,85,0.6);
  color: #333;
}
* + .card__summary,
* + .card__teaser {
  margin-top: 0.539333333333333rem;
}
.centre_introduction {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 92%;
  max-width: 1600px;
  margin: 0 auto;
}
@media only screen and (min-width: 1081px) {
  .centre_introduction {
    flex-direction: row;
  }
}
* + .centre_introduction {
  margin-top: 3.236rem;
}
.centre_introduction__feature_image {
  flex: 0 1 50%;
  height: auto;
  box-shadow: 0 12px 17px -5px rgba(31,31,31,0.2), 0 35px 110px 0px rgba(31,31,31,0.1);
}
.centre_introduction__feature_image + .centre_introduction__text {
  flex: 0 1 50%;
  text-align: left;
}
.centre_introduction__feature_image + .centre_introduction__text p {
  font-size: 1.125rem;
  font-size: var(--fz-ratio--one);
}
@media only screen and (min-width: 681px) {
  .centre_introduction__feature_image + .centre_introduction__text p {
    font-size: 1.2125rem;
    font-size: var(--fz-ratio--one);
  }
}
@media only screen and (min-width: 1281px) {
  .centre_introduction__feature_image + .centre_introduction__text p {
    font-size: 1.3rem;
    font-size: var(--fz-ratio--one);
  }
}
.centre_introduction__image {
  min-width: 100%;
}
.centre_introduction__text {
  text-align: center;
  width: 100%;
  max-width: 1180px;
  margin: 0 auto;
}
@media only screen and (max-width: 1480px) {
  * + .centre_introduction__text {
    margin-top: 2.427rem;
  }
}
@media only screen and (min-width: 1081px) {
  * + .centre_introduction__text {
    margin-left: 3.236rem;
  }
}
.centre_introduction__text p {
  font-size: 1.423828125rem;
  font-size: var(--fz-ratio--three);
}
@media only screen and (min-width: 681px) {
  .centre_introduction__text p {
    font-size: 1.782564453125rem;
    font-size: var(--fz-ratio--three);
  }
}
@media only screen and (min-width: 1281px) {
  .centre_introduction__text p {
    font-size: 2.197000000000001rem;
    font-size: var(--fz-ratio--three);
  }
}
.cookie_notice {
  z-index: 90;
  position: fixed;
  right: 0;
  bottom: 0;
  display: none;
  box-shadow: 0 -1px 1px rgba(24,24,24,0.02), 0 -2px 2px rgba(24,24,24,0.02), 0 -4px 4px rgba(24,24,24,0.02), 0 -8px 8px rgba(24,24,24,0.02), 0 -16px 16px rgba(24,24,24,0.02);
  border-radius: 5px 0 0 0;
  border-top: 1px solid #fff1cf;
  border-left: 1px solid #fff1cf;
  max-width: 30rem;
  background-color: #fff9e3;
  padding: 0.809rem 1.077588rem 1.077588rem;
}
.cookie_notice--is_visible {
  display: inline-block;
}
.cookie_notice__text,
.cookie_notice__dismiss {
  color: #181818;
}
.cookie_notice__text {
  display: block;
  font-size: 0.888888888888889rem;
  font-size: var(--fz-ratio--minus-one);
  letter-spacing: -0.008em;
}
@media only screen and (min-width: 681px) {
  .cookie_notice__text {
    font-size: 0.824742268041237rem;
    font-size: var(--fz-ratio--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .cookie_notice__text {
    font-size: 0.769230769230769rem;
    font-size: var(--fz-ratio--minus-one);
  }
}
.cookie_notice__text a,
.cookie_notice__text a:visited {
  box-shadow: inset 0 -1px #fed169;
  color: #333;
}
.cookie_notice__text a:active,
.cookie_notice__text a:visited:active,
.cookie_notice__text a:focus,
.cookie_notice__text a:visited:focus,
.cookie_notice__text a:hover,
.cookie_notice__text a:visited:hover {
  box-shadow: inset 0 -0.24em #fed169;
}
.cookie_notice__dismiss {
  display: inline-block;
  border-radius: 5px;
  border: 1px solid #fff1cf;
  background-color: #fff;
  padding: 0.1618rem 0.539333333333333rem 0.20225rem;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  font-size: 0.790123456790123rem;
  font-size: var(--fz-ratio--minus-two);
}
.cookie_notice__dismiss:active,
.cookie_notice__dismiss:focus,
.cookie_notice__dismiss:hover {
  transform: translateY(-1px);
}
* + .cookie_notice__dismiss {
  margin-top: 0.809rem;
}
@media only screen and (min-width: 681px) {
  .cookie_notice__dismiss {
    font-size: 0.680199808693804rem;
    font-size: var(--fz-ratio--minus-two);
  }
}
@media only screen and (min-width: 1281px) {
  .cookie_notice__dismiss {
    font-size: 0.591715976331361rem;
    font-size: var(--fz-ratio--minus-two);
  }
}
.countdown {
  width: 100%;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  width: 1600px;
  max-width: 100%;
  background-color: #e5e9f1;
}
* + .countdown {
  margin-top: 3.236rem;
}
.countdown__inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 2.427rem;
}
.countdown__text {
  text-transform: uppercase;
  font-size: 1.265625rem;
  font-size: var(--fz-ratio--two);
  font-weight: 700;
}
@media only screen and (min-width: 681px) {
  .countdown__text {
    font-size: 1.47015625rem;
    font-size: var(--fz-ratio--two);
  }
}
@media only screen and (min-width: 1281px) {
  .countdown__text {
    font-size: 1.69rem;
    font-size: var(--fz-ratio--two);
  }
}
.countdown__time {
  animation: 1s countdown-blip linear infinite;
  font-family: 'Roboto Mono', monospace;
  line-height: 1.4;
  letter-spacing: -0.2rem;
}
* + .countdown__time {
  margin-top: 1.618rem;
}
.countdown__time {
  font-size: 26px;
}
@media screen and (min-width: 320px) {
  .countdown__time {
    font-size: calc(5.3164556962025316vw + 8.9873417721519px);
  }
}
@media screen and (min-width: 1900px) {
  .countdown__time {
    font-size: 110px;
  }
}
.download_app {
  width: 100%;
}
@media only screen and (min-width: 881px) {
  .download_app {
    text-align: right;
  }
}
.download_app__title {
  font-size: 0.888888888888889rem;
  font-size: var(--fz-ratio--minus-one);
  color: #555;
}
@media only screen and (min-width: 681px) {
  .download_app__title {
    font-size: 0.824742268041237rem;
    font-size: var(--fz-ratio--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .download_app__title {
    font-size: 0.769230769230769rem;
    font-size: var(--fz-ratio--minus-one);
  }
}
.download_app__list {
  display: flex;
  justify-content: flex-start;
  margin-top: 0.4045rem;
}
@media only screen and (min-width: 881px) {
  .download_app__list {
    justify-content: flex-end;
  }
}
* + .download_app__item {
  margin-left: 1.077588rem;
}
.download_app__anchor:link img,
.download_app__anchor:visited img {
  opacity: 0.85;
/*
        the below line should be commented out because we have exceeded most browsers'
        will-change budget allowance. we can fix this by only using one or
        two properties as a default and then specifying properties every time
        we call the mixin, but at present removing default properties from this
        mixin causes many of the site's styles to break, and actually commenting
        out the line also causes styles to break.
    */
  transition-property: outline-offset, opacity;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}
.download_app__anchor:hover img {
  opacity: 0.6;
}
.download_app__anchor:active img,
.download_app__anchor:focus img {
  opacity: 1;
}
.download_app__anchor img {
  height: 48px;
  width: auto;
}
.file_download {
  display: flex;
  margin-right: auto;
  margin-left: auto;
  width: 92%;
  max-width: 1600px;
  justify-content: center;
}
* + .file_download {
  margin-top: 3.236rem;
}
.file_download__inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0 12px 17px -5px rgba(31,31,31,0.2), 0 35px 110px 0px rgba(31,31,31,0.1);
  max-width: 42rem;
  background-color: #fff;
  padding: 1.618rem;
}
.file_download__heading {
  font-size: 1.265625rem;
  font-size: var(--fz-ratio--two);
  font-weight: 700;
}
@media only screen and (min-width: 681px) {
  .file_download__heading {
    font-size: 1.47015625rem;
    font-size: var(--fz-ratio--two);
  }
}
@media only screen and (min-width: 1281px) {
  .file_download__heading {
    font-size: 1.69rem;
    font-size: var(--fz-ratio--two);
  }
}
.file_download__summary {
  color: #333;
}
.file_download__anchor {
  display: inline-block;
}
.file_download__divider {
  color: #fff;
}
.filter_form,
#mc_embed_signup {
  display: block;
  margin-right: auto;
  margin-left: auto;
  width: 92%;
  max-width: 1600px;
}
* + .filter_form,
* + #mc_embed_signup {
  margin-top: 3.236rem;
}
.filter_form select,
#mc_embed_signup select {
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  max-width: 100%;
  box-shadow: none;
  border-radius: 5px;
  border: 0;
  background-color: #fff;
  background-image: url("../images/down_caret.svg");
  background-position: right 0.809rem top 50%;
  background-repeat: no-repeat;
  background-size: 0.809rem;
  padding: 0.4045rem 2.427rem 0.4045rem 0.4045rem;
}
.filter_form select::-ms-expand,
#mc_embed_signup select::-ms-expand {
  display: none;
}
.filter_form__inner,
#mc_embed_signup__inner {
  overflow: hidden;
  border-radius: 5px;
  background-color: #e5e9f1;
  padding: 1.618rem 4vw;
}
@media only screen and (min-width: 881px) {
  .filter_form__inner,
  #mc_embed_signup__inner {
    padding: 1.618rem;
  }
}
.filter_form__intro-text,
#mc_embed_signup__intro-text,
.filter_form__label--centre,
#mc_embed_signup__label--centre {
  font-weight: 700;
  display: block;
  width: 100%;
  margin-bottom: 0.809rem;
}
.filter_form--centre,
#mc_embed_signup--centre {
  margin-bottom: 0.20225rem;
}
.filter_form--centre .filter_form__item,
#mc_embed_signup--centre #mc_embed_signup__item {
  flex: auto;
}
.filter_form__form-contents,
#mc_embed_signup__form-contents {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
}
@media only screen and (min-width: 881px) {
  .filter_form__form-contents,
  #mc_embed_signup__form-contents {
    flex-direction: row;
  }
}
.filter_form__item,
#mc_embed_signup__item {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media only screen and (min-width: 881px) {
  .filter_form__item,
  #mc_embed_signup__item {
    flex: 1;
  }
}
* + .filter_form__item,
* + #mc_embed_signup__item {
  margin-top: 2%;
}
@media only screen and (min-width: 881px) {
  * + .filter_form__item,
  * + #mc_embed_signup__item {
    margin-top: 0;
  }
  * + .filter_form__item,
  * + #mc_embed_signup__item {
    margin-left: 2%;
  }
}
.filter_form__item--keywords,
#mc_embed_signup__item--keywords,
.filter_form__item--category,
#mc_embed_signup__item--category {
  flex: 0 0 auto;
}
@media only screen and (min-width: 881px) {
  .filter_form__item--keywords,
  #mc_embed_signup__item--keywords,
  .filter_form__item--category,
  #mc_embed_signup__item--category {
    flex: 2;
  }
}
.filter_form__button,
#mc_embed_signup__button {
  height: 52px;
  font-size: 0.888888888888889rem;
  font-size: var(--fz-ratio--minus-one);
  display: flex;
  justify-content: center;
  align-content: center;
  background-color: #fff;
  color: #333;
  box-shadow: 0px 6px 12px -2px rgba(51,51,51,0.08), 0px 2px 24px rgba(51,51,51,0.02);
}
.filter_form__button,
#mc_embed_signup__button,
.filter_form__button:link,
#mc_embed_signup__button:link,
.filter_form__button:visited,
#mc_embed_signup__button:visited {
  display: inline-block;
  box-shadow: 0 0 0 2px transparent;
  border-radius: 5px;
  border: 0;
  background-color: #e8eaee;
  text-align: center;
  font-weight: 400;
  color: #555;
}
.filter_form__button .icon,
#mc_embed_signup__button .icon,
.filter_form__button:link .icon,
#mc_embed_signup__button:link .icon,
.filter_form__button:visited .icon,
#mc_embed_signup__button:visited .icon {
  flex: 0 0 auto;
  margin-left: 0.809rem;
  height: 32px;
  width: 14px;
}
.filter_form__button:active,
#mc_embed_signup__button:active,
.filter_form__button:focus,
#mc_embed_signup__button:focus,
.filter_form__button:hover,
#mc_embed_signup__button:hover {
  cursor: pointer;
  background-color: #d3d6de;
  color: #333;
}
@media only screen and (min-width: 681px) {
  .filter_form__button,
  #mc_embed_signup__button {
    font-size: 0.824742268041237rem;
    font-size: var(--fz-ratio--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .filter_form__button,
  #mc_embed_signup__button {
    font-size: 0.769230769230769rem;
    font-size: var(--fz-ratio--minus-one);
  }
}
.filter_form__button .icon,
#mc_embed_signup__button .icon {
  fill: #333;
}
.filter_form__button:hover,
#mc_embed_signup__button:hover {
  background-color: #fff;
  box-shadow: 0px 10px 20px rgba(51,51,51,0.12), 0px 4px 30px rgba(51,51,51,0.02);
}
.filter_form__button--previous .icon,
#mc_embed_signup__button--previous .icon,
.filter_form__button--next .icon,
#mc_embed_signup__button--next .icon {
  fill: #555;
}
.filter_form__button--previous .icon,
#mc_embed_signup__button--previous .icon {
  transform-origin: 50%;
  transform: rotate(180deg);
  margin: 0;
  margin-right: 0.809rem;
}
.filter_form__button--next .icon,
#mc_embed_signup__button--next .icon {
  margin-left: 0.809rem;
}
.filter_form__label,
#mc_embed_signup__label {
  font-size: 0.888888888888889rem;
  font-size: var(--fz-ratio--minus-one);
  margin-bottom: 0.269666666666667rem;
}
@media only screen and (min-width: 681px) {
  .filter_form__label,
  #mc_embed_signup__label {
    font-size: 0.824742268041237rem;
    font-size: var(--fz-ratio--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .filter_form__label,
  #mc_embed_signup__label {
    font-size: 0.769230769230769rem;
    font-size: var(--fz-ratio--minus-one);
  }
}
.filter_form__input,
#mc_embed_signup__input,
.filter_form__date,
#mc_embed_signup__date,
.filter_form input[type="date"],
#mc_embed_signup input[type="date"] {
  border: 0;
  border-radius: 5px;
  background-color: #fff;
  padding: 0.4045rem 0.809rem;
  color: #181818;
}
.filter_form__input::placeholder,
#mc_embed_signup__input::placeholder,
.filter_form__date::placeholder,
#mc_embed_signup__date::placeholder,
.filter_form input[type="date"]::placeholder,
#mc_embed_signup input[type="date"]::placeholder {
  color: #777;
}
.filter_form__date,
#mc_embed_signup__date {
  color: #777;
}
.filter_form__date:active,
#mc_embed_signup__date:active,
.filter_form__date:focus,
#mc_embed_signup__date:focus,
.filter_form__date:hover,
#mc_embed_signup__date:hover {
  color: #181818;
}
.filter_form__select,
#mc_embed_signup__select {
  font-size: 0.888888888888889rem;
  font-size: var(--fz-ratio--minus-one);
  border: 0;
  color: #181818;
  padding-left: 0.4045rem;
  background-color: #fff;
}
@media only screen and (min-width: 681px) {
  .filter_form__select,
  #mc_embed_signup__select {
    font-size: 0.824742268041237rem;
    font-size: var(--fz-ratio--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .filter_form__select,
  #mc_embed_signup__select {
    font-size: 0.769230769230769rem;
    font-size: var(--fz-ratio--minus-one);
  }
}
.filter_form__action,
#mc_embed_signup__action {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2%;
}
@media only screen and (min-width: 881px) {
  .filter_form__action,
  #mc_embed_signup__action {
    margin-top: 1.2135rem;
  }
}
* + .filter_form__reset,
* + #mc_embed_signup__reset,
* + .filter_form__submit,
* + #mc_embed_signup__submit {
  margin-top: 0;
}
* + .filter_form__reset,
* + #mc_embed_signup__reset,
* + .filter_form__submit,
* + #mc_embed_signup__submit {
  margin-left: 2%;
}
.filter_form__reset:hover,
#mc_embed_signup__reset:hover,
.filter_form__submit:hover,
#mc_embed_signup__submit:hover {
  color: #000;
}
.filter_form--timetable .filter_form__item,
#mc_embed_signup--timetable #mc_embed_signup__item {
  justify-content: flex-end;
}
.form_component {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-left: 5px solid #aac91b;
  border-radius: 5px 0 0 5px;
  width: 100%;
  padding-left: 1.077588rem;
}
*:not([type="hidden"]) + .form_component {
  margin-top: 1.618rem;
}
.form_component > * {
  flex: 0 0 auto;
}
.form_component__checkbox {
  position: relative;
  z-index: auto;
  display: block;
}
.form_component__checkbox + .form_component__checkbox {
  margin-top: 0.539333333333333rem;
}
.form_component__checkbox__input {
  cursor: pointer;
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  padding: 0;
}
.form_component__checkbox__label {
  cursor: pointer;
  position: relative;
  z-index: auto;
  display: inline-block;
  padding-left: 1.752833333333333rem;
}
.form_component__checkbox__label::before,
.form_component__checkbox__label::after {
/*
        the below line should be commented out because we have exceeded most browsers'
        will-change budget allowance. we can fix this by only using one or
        two properties as a default and then specifying properties every time
        we call the mixin, but at present removing default properties from this
        mixin causes many of the site's styles to break, and actually commenting
        out the line also causes styles to break.
    */
  transition-property: outline-offset, border-color, opacity, transform, zoom;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  content: "";
  position: absolute;
  display: inline-block;
}
.form_component__checkbox__label::before {
  top: 0.269666666666667rem;
  left: 0;
  height: 1.2135rem;
  width: 1.2135rem;
  border-radius: 5px;
  border: 1px solid #509ece;
  background-color: #fff;
}
.form_component__checkbox__input:focus + .form_component__checkbox__label::before {
  z-index: 1;
  outline: 2px dashed #fcbf30;
  outline-offset: 6px;
  overflow: visible;
}
.form_component__checkbox__input:checked + .form_component__checkbox__label::before {
  border-color: #509ece;
}
.form_component__checkbox__label::after {
  transform: rotate(-10deg) scale(0.4);
  top: 0.58rem;
  left: 0.2rem;
  height: 0.4045rem;
  width: 0.809rem;
  border-bottom: 2px solid #509ece;
  border-left: 2px solid #509ece;
}
.form_component__checkbox__input + .form_component__checkbox__label::after {
  opacity: 0;
}
.form_component__checkbox__input:checked + .form_component__checkbox__label::after {
  z-index: 90;
  opacity: 1;
  transform: rotate(-45deg) scale(1);
}
.form_component--date {
  flex-direction: row;
  align-items: stretch;
}
.form_component--date .form_component__label {
  flex: 0 0 auto;
  position: relative;
  z-index: auto;
  width: auto;
  max-width: 100%;
  border-radius: 5px 0 0 5px;
  border: 1px solid #509ece;
  border-right: 0;
  background-color: #fff;
  padding: 0.539333333333333rem;
  line-height: 1.618;
}
.form_component--date .form_component__label:first-child {
  margin-top: 0;
}
.form_component--date .form_component__label::before {
  content: '';
  position: absolute;
  z-index: auto;
  top: 20%;
  right: 0;
  height: 60%;
  width: 1px;
  background-color: #85c9f0;
}
.form_component--date .form_component__label::after {
  content: ':';
}
.form_component__date {
/*
        the below line should be commented out because we have exceeded most browsers'
        will-change budget allowance. we can fix this by only using one or
        two properties as a default and then specifying properties every time
        we call the mixin, but at present removing default properties from this
        mixin causes many of the site's styles to break, and actually commenting
        out the line also causes styles to break.
    */
  transition-property: outline-offset, background-color, box-shadow, color, opacity;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  flex: 1 1 auto;
  cursor: pointer;
  width: auto;
  max-width: 100%;
  box-shadow: none;
}
.form {
  margin: 0 auto;
  max-width: 1180px;
  width: 92%;
}
* + .form {
  margin-top: 1.618rem;
}
.form *:not(button):required:invalid:focus,
.form *:not(button):required:invalid:active {
  border: 1px solid #ee5432;
}
.form *:not(button):required:valid:focus,
.form *:not(button):required:valid:active {
  border: 1px solid #2fd060;
}
.form_component__label {
  display: block;
  width: 100%;
  max-width: 78ch;
}
.form_component__label:first-child {
  margin-top: -0.3236rem;
}
.form_component__number {
/*
        the below line should be commented out because we have exceeded most browsers'
        will-change budget allowance. we can fix this by only using one or
        two properties as a default and then specifying properties every time
        we call the mixin, but at present removing default properties from this
        mixin causes many of the site's styles to break, and actually commenting
        out the line also causes styles to break.
    */
  transition-property: outline-offset, background-color, box-shadow, color, opacity;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  width: 78ch;
  max-width: 100%;
  box-shadow: none;
  border-radius: 5px;
  border: 1px solid #509ece;
  background-color: #fff;
  padding: 0.539333333333333rem;
}
.form_component__number::-webkit-input-placeholder {
  color: #666;
  opacity: 1;
}
.form_component__number:-moz-placeholder {
  color: #666;
  opacity: 1;
}
.form_component__number::-moz-placeholder {
  color: #666;
  opacity: 1;
}
.form_component__number:-ms-input-placeholder {
  color: #666;
  opacity: 1;
}
.form_component__number:active,
.form_component__number:focus,
.form_component__number:hover {
  outline: 0;
}
label + .form_component__number {
  margin-top: 0.539333333333333rem;
}
.form_component__password {
/*
        the below line should be commented out because we have exceeded most browsers'
        will-change budget allowance. we can fix this by only using one or
        two properties as a default and then specifying properties every time
        we call the mixin, but at present removing default properties from this
        mixin causes many of the site's styles to break, and actually commenting
        out the line also causes styles to break.
    */
  transition-property: outline-offset, background-color, box-shadow, color, opacity;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  width: 78ch;
  max-width: 100%;
  box-shadow: none;
  border-radius: 5px;
  border: 1px solid #509ece;
  background-color: #fff;
  padding: 0.539333333333333rem;
}
.form_component__password::-webkit-input-placeholder {
  color: #666;
  opacity: 1;
}
.form_component__password:-moz-placeholder {
  color: #666;
  opacity: 1;
}
.form_component__password::-moz-placeholder {
  color: #666;
  opacity: 1;
}
.form_component__password:-ms-input-placeholder {
  color: #666;
  opacity: 1;
}
.form_component__password:active,
.form_component__password:focus,
.form_component__password:hover {
  outline: 0;
}
label + .form_component__password {
  margin-top: 0.539333333333333rem;
}
.form_component__radio {
  position: relative;
  z-index: auto;
  display: flex;
  width: 100%;
  max-width: 78ch;
}
.form_component__radio + .form_component__radio {
  margin-top: 0.539333333333333rem;
}
.form_component__radio__input {
  cursor: pointer;
  z-index: 10;
  position: absolute;
  opacity: 0;
  margin: 0;
  border: 0;
  height: 100%;
  width: 100%;
  padding: 0;
}
.form_component__radio__label {
  position: relative;
  z-index: auto;
  display: inline-block;
  padding-left: calc(0.539333333333333rem + 20px);
  color: #333;
}
@media only screen and (max-width: 1080px) {
  .form_component__radio__label {
    padding-top: 0.20225rem;
  }
}
.form_component__radio__label::before,
.form_component__radio__label::after {
/*
        the below line should be commented out because we have exceeded most browsers'
        will-change budget allowance. we can fix this by only using one or
        two properties as a default and then specifying properties every time
        we call the mixin, but at present removing default properties from this
        mixin causes many of the site's styles to break, and actually commenting
        out the line also causes styles to break.
    */
  transition-property: outline-offset, border-color, opacity, scale, transform;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  content: "";
  position: absolute;
  display: inline-block;
}
.form_component__radio__label::before {
  top: 0.3236rem;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 1px solid #333;
  background-color: #fff;
}
.form_component__radio__input:focus + .form_component__radio__label::before {
  z-index: 1;
  outline: 2px dashed #fcbf30;
  outline-offset: 6px;
  overflow: visible;
}
.form_component__radio__input:checked + .form_component__radio__label::before,
.form_component__radio__input:focus:checked + .form_component__radio__label::before {
  border-color: #333;
}
.form_component__radio__label::after {
  transform: scale(0.1);
  top: calc(0.3236rem + 5px);
  left: 5px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  border: 0;
  background-color: #333;
}
.form_component__radio__input + .form_component__radio__label::after {
  opacity: 0;
}
.form_component__radio__input:checked + .form_component__radio__label::after {
  z-index: 90;
  opacity: 1;
  transform: scale(0.8);
}
.form_component__select_container {
  position: relative;
  z-index: auto;
  width: 100%;
  max-width: 78ch;
}
.filter_form .form_component__select_container {
  width: auto;
}
.form_component__select_container::before {
  content: '▼';
  pointer-events: none;
  transform: scale(1.5, 1) translateX(-10%) translateY(-50%);
  position: absolute;
  top: 50%;
  right: 0.809rem;
  color: #333;
}
label + .form_component__select_container {
  margin-top: 0.539333333333333rem;
}
.form_component__select {
/*
        the below line should be commented out because we have exceeded most browsers'
        will-change budget allowance. we can fix this by only using one or
        two properties as a default and then specifying properties every time
        we call the mixin, but at present removing default properties from this
        mixin causes many of the site's styles to break, and actually commenting
        out the line also causes styles to break.
    */
  transition-property: outline-offset, border-color;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  box-shadow: none;
  border-radius: 5px;
  border: 1px solid #333;
  background-color: #fff;
  background-position: right 0.809rem top 50%;
  background-repeat: no-repeat;
  background-size: 1.2135rem;
  padding: 0.539333333333333rem 2.427rem 0.539333333333333rem 0.6472rem;
  padding-bottom: calc(0.539333333333333rem + 3px);
  color: #333;
}
.filter_form .form_component__select {
  padding: 0.269666666666667rem 2.157333333333333rem 0.4045rem 0.269666666666667rem;
}
.form_component__tel {
/*
        the below line should be commented out because we have exceeded most browsers'
        will-change budget allowance. we can fix this by only using one or
        two properties as a default and then specifying properties every time
        we call the mixin, but at present removing default properties from this
        mixin causes many of the site's styles to break, and actually commenting
        out the line also causes styles to break.
    */
  transition-property: outline-offset, background-color, box-shadow, color, opacity;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  width: 78ch;
  max-width: 100%;
  box-shadow: none;
  border-radius: 5px;
  border: 1px solid #509ece;
  background-color: #fff;
  padding: 0.539333333333333rem;
}
.form_component__tel::-webkit-input-placeholder {
  color: #666;
  opacity: 1;
}
.form_component__tel:-moz-placeholder {
  color: #666;
  opacity: 1;
}
.form_component__tel::-moz-placeholder {
  color: #666;
  opacity: 1;
}
.form_component__tel:-ms-input-placeholder {
  color: #666;
  opacity: 1;
}
.form_component__tel:active,
.form_component__tel:focus,
.form_component__tel:hover {
  outline: 0;
}
label + .form_component__tel {
  margin-top: 0.539333333333333rem;
}
.form_component__text,
.form_component__tel {
  width: 78ch;
  max-width: 100%;
  box-shadow: none;
  border-radius: 5px;
  border: 1px solid #333;
  background-color: #fff;
  padding: 0.539333333333333rem;
}
.form_component__text::-webkit-input-placeholder,
.form_component__tel::-webkit-input-placeholder {
  color: #666;
  opacity: 1;
}
.form_component__text:-moz-placeholder,
.form_component__tel:-moz-placeholder {
  color: #666;
  opacity: 1;
}
.form_component__text::-moz-placeholder,
.form_component__tel::-moz-placeholder {
  color: #666;
  opacity: 1;
}
.form_component__text:-ms-input-placeholder,
.form_component__tel:-ms-input-placeholder {
  color: #666;
  opacity: 1;
}
.form_component__text:active,
.form_component__tel:active,
.form_component__text:focus,
.form_component__tel:focus,
.form_component__text:hover,
.form_component__tel:hover {
  outline: 0;
}
label + .form_component__text,
label + .form_component__tel {
  margin-top: 0.539333333333333rem;
}
.form_component__textarea {
/*
        the below line should be commented out because we have exceeded most browsers'
        will-change budget allowance. we can fix this by only using one or
        two properties as a default and then specifying properties every time
        we call the mixin, but at present removing default properties from this
        mixin causes many of the site's styles to break, and actually commenting
        out the line also causes styles to break.
    */
  transition-property: outline-offset, background-color, box-shadow, color, opacity;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  resize: none;
  width: 78ch;
  max-width: 100%;
  box-shadow: none;
  border-radius: 5px;
  border: 1px solid #666;
  background-color: #fff;
  padding: 0.539333333333333rem;
}
.form_component__textarea::-webkit-input-placeholder {
  color: #666;
  opacity: 1;
}
.form_component__textarea:-moz-placeholder {
  color: #666;
  opacity: 1;
}
.form_component__textarea::-moz-placeholder {
  color: #666;
  opacity: 1;
}
.form_component__textarea:-ms-input-placeholder {
  color: #666;
  opacity: 1;
}
.form_component__textarea:active,
.form_component__textarea:focus,
.form_component__textarea:hover {
  outline: 0;
}
label + .form_component__textarea {
  margin-top: 0.539333333333333rem;
}
.google-map {
  margin: 0 auto;
  width: 100%;
  max-width: 1600px;
}
.google-map::before {
  content: '';
  display: block;
  width: 100%;
  padding-bottom: 50%;
}
.grid {
  display: flex;
  margin-right: auto;
  margin-left: auto;
  width: 92%;
  max-width: 1600px;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 1.618rem;
  margin-bottom: 1.618rem;
}
@media only screen and (min-width: 1081px) {
  .grid {
    margin-top: 3.236rem;
  }
}
@media only screen and (min-width: 1081px) {
  .grid {
    margin-bottom: 3.236rem;
  }
}
.grid--two__list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.grid--two__item,
.grid--two .card {
  flex: 0 0 100%;
}
@media only screen and (min-width: 681px) {
  .grid--two__item,
  .grid--two .card {
    flex: 0 0 48%;
  }
}
@media only screen and (min-width: 681px) {
  .grid--two__item:nth-child(even),
  .grid--two .card:nth-child(even) {
    margin-left: 4%;
  }
}
@media only screen and (max-width: 680px) {
  .grid--two__item:nth-child(n+2),
  .grid--two .card:nth-child(n+2) {
    margin-top: 4%;
  }
}
@media only screen and (min-width: 681px) {
  .grid--two__item:nth-child(n+3),
  .grid--two .card:nth-child(n+3) {
    margin-top: 4%;
  }
}
@media only screen and (min-width: 681px) {
  .grid--two__item:last-child,
  .grid--two .card:last-child {
    margin-right: auto;
  }
}
.grid--three__list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.grid--three__item,
.grid--three .card {
  flex: 0 0 100%;
}
@media only screen and (min-width: 681px) {
  .grid--three__item,
  .grid--three .card {
    flex: 0 0 48%;
  }
}
@media only screen and (min-width: 1081px) {
  .grid--three__item,
  .grid--three .card {
    flex: 0 0 30.666666666666668%;
  }
}
@media only screen and (min-width: 681px) and (max-width: 1080px) {
  .grid--three__item:nth-child(even),
  .grid--three .card:nth-child(even) {
    margin-left: 4%;
  }
}
@media only screen and (min-width: 1081px) {
  .grid--three__item:nth-child(3n+2),
  .grid--three .card:nth-child(3n+2),
  .grid--three__item:nth-child(3n+3),
  .grid--three .card:nth-child(3n+3) {
    margin-left: 4%;
  }
}
@media only screen and (max-width: 680px) {
  .grid--three__item:nth-child(n+2),
  .grid--three .card:nth-child(n+2) {
    margin-top: 4%;
  }
}
@media only screen and (max-width: 1080px) {
  .grid--three__item:nth-child(n+3),
  .grid--three .card:nth-child(n+3) {
    margin-top: 4%;
  }
}
@media only screen and (min-width: 1081px) {
  .grid--three__item:nth-child(n+4),
  .grid--three .card:nth-child(n+4) {
    margin-top: 4%;
  }
}
@media only screen and (min-width: 681px) {
  .grid--three__item:last-child,
  .grid--three .card:last-child {
    margin-right: auto;
  }
}
.grid--four__list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.grid--four__item,
.grid--four .card {
  flex: 0 0 100%;
}
@media only screen and (min-width: 681px) {
  .grid--four__item,
  .grid--four .card {
    flex: 0 0 48%;
  }
}
@media only screen and (min-width: 1081px) {
  .grid--four__item,
  .grid--four .card {
    flex: 0 0 30.666666666666668%;
  }
}
@media only screen and (min-width: 681px) and (max-width: 1080px) {
  .grid--four__item:nth-child(even),
  .grid--four .card:nth-child(even) {
    margin-left: 4%;
  }
}
@media only screen and (min-width: 1081px) {
  .grid--four__item:nth-child(3n+2),
  .grid--four .card:nth-child(3n+2),
  .grid--four__item:nth-child(3n+3),
  .grid--four .card:nth-child(3n+3) {
    margin-left: 4%;
  }
}
@media only screen and (max-width: 680px) {
  .grid--four__item:nth-child(n+2),
  .grid--four .card:nth-child(n+2) {
    margin-top: 4%;
  }
}
@media only screen and (max-width: 1080px) {
  .grid--four__item:nth-child(n+3),
  .grid--four .card:nth-child(n+3) {
    margin-top: 4%;
  }
}
@media only screen and (min-width: 1081px) {
  .grid--four__item:nth-child(n+4),
  .grid--four .card:nth-child(n+4) {
    margin-top: 4%;
  }
}
@media only screen and (min-width: 681px) {
  .grid--four__item:last-child,
  .grid--four .card:last-child {
    margin-right: auto;
  }
}
.heading_block {
  display: block;
  width: 100%;
}
* + .heading_block--heading_two {
  margin-top: 1.993359375rem;
  margin-top: var(--sp-ratio--three);
}
@media only screen and (min-width: 681px) {
  * + .heading_block--heading_two {
    margin-top: 2.495590234374999rem;
    margin-top: var(--sp-ratio--three);
  }
}
@media only screen and (min-width: 1281px) {
  * + .heading_block--heading_two {
    margin-top: 3.075800000000001rem;
    margin-top: var(--sp-ratio--three);
  }
}
* + .heading_block--heading_three {
  margin-top: 1.771875rem;
  margin-top: var(--sp-ratio--two);
}
@media only screen and (min-width: 681px) {
  * + .heading_block--heading_three {
    margin-top: 2.05821875rem;
    margin-top: var(--sp-ratio--two);
  }
}
@media only screen and (min-width: 1281px) {
  * + .heading_block--heading_three {
    margin-top: 2.366rem;
    margin-top: var(--sp-ratio--two);
  }
}
* + .heading_block--heading_four {
  margin-top: 1.575rem;
  margin-top: var(--sp-ratio--one);
}
@media only screen and (min-width: 681px) {
  * + .heading_block--heading_four {
    margin-top: 1.6975rem;
    margin-top: var(--sp-ratio--one);
  }
}
@media only screen and (min-width: 1281px) {
  * + .heading_block--heading_four {
    margin-top: 1.82rem;
    margin-top: var(--sp-ratio--one);
  }
}
.heading_block__inner {
  display: flex;
  margin-right: auto;
  margin-left: auto;
  width: 92%;
  max-width: 880px;
  flex-direction: column;
}
.heading_block h2 {
  max-width: 46.8ch;
}
.heading_block h3 {
  max-width: 54.599999999999994ch;
}
.heading_block h4 {
  max-width: 62.400000000000006ch;
}
* + .hero_image_and_strapline,
* + .hero_image,
* + .strapline {
  margin-top: 1.618rem;
}
.hero_image_and_strapline {
  display: flex;
  margin-right: auto;
  margin-left: auto;
  width: 92%;
  max-width: 1600px;
  flex-direction: column;
  align-items: center;
}
.hero_image_and_strapline__inner {
  position: relative;
  z-index: auto;
  display: inline-block;
  max-height: 100%;
  width: auto;
  max-width: 100%;
}
.hero_image_and_strapline__strapline {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}
@media only screen and (min-width: 881px) {
  .hero_image_and_strapline__strapline {
    position: absolute;
    right: 0;
    bottom: -1px;
    left: 0;
  }
}
.hero_image_and_strapline__strapline_text {
  display: inline-block;
  width: 100%;
  box-shadow: 0 2px 6px rgba(31,31,31,0.2);
  background-color: #fff;
  padding: 0.4045rem 0.809rem 0.539333333333333rem;
  font-size: 1.265625rem;
  font-size: var(--fz-ratio--two);
}
@media only screen and (min-width: 881px) {
  .hero_image_and_strapline__strapline_text {
    max-width: 57ch;
  }
}
@media only screen and (min-width: 881px) {
  .hero_image_and_strapline__strapline_text {
    box-shadow: 0 12px 17px -5px rgba(31,31,31,0.15), 0 35px 110px 0px rgba(31,31,31,0.1);
  }
}
@media only screen and (min-width: 681px) {
  .hero_image_and_strapline__strapline_text {
    font-size: 1.47015625rem;
    font-size: var(--fz-ratio--two);
  }
}
@media only screen and (min-width: 1281px) {
  .hero_image_and_strapline__strapline_text {
    font-size: 1.69rem;
    font-size: var(--fz-ratio--two);
  }
}
.hero_image__image {
  box-shadow: 0 2px 6px rgba(31,31,31,0.2);
  max-height: 100vh;
}
.strapline {
  border-left: 6px solid #fff;
  width: 92%;
  max-width: 1600px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 0.809rem;
  padding-bottom: 0.3236rem;
}
.strapline__text {
  font-size: 1.265625rem;
  font-size: var(--fz-ratio--two);
  width: 58ch;
}
@media only screen and (min-width: 681px) {
  .strapline__text {
    font-size: 1.47015625rem;
    font-size: var(--fz-ratio--two);
  }
}
@media only screen and (min-width: 1281px) {
  .strapline__text {
    font-size: 1.69rem;
    font-size: var(--fz-ratio--two);
  }
}
.hero {
  position: relative;
  z-index: auto;
  overflow: hidden;
  width: 100%;
  color: #fff;
}
.hero__background {
/*
        the below line should be commented out because we have exceeded most browsers'
        will-change budget allowance. we can fix this by only using one or
        two properties as a default and then specifying properties every time
        we call the mixin, but at present removing default properties from this
        mixin causes many of the site's styles to break, and actually commenting
        out the line also causes styles to break.
    */
  transition-property: outline-offset, background-color, box-shadow, color, opacity;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  pointer-events: none;
  z-index: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.hero__background--first {
  background: linear-gradient(135deg, #809714 0%, #88a116 55%, #aac91b 100%);
}
.hero__background--second {
  background: linear-gradient(135deg, #3282b3 0%, #509ece 100%);
  animation: hero02 20s infinite;
}
.hero__background--third {
  background: linear-gradient(135deg, #c71917 0%, #e8461f 80%);
  animation: hero03 20s infinite;
}
.hero__background_image {
  position: absolute;
  bottom: 0;
  height: 66%;
  width: auto;
  opacity: 0.175;
}
.hero__background_image--left {
  left: 0;
  height: 1200px;
}
.hero__background_image--right {
  right: 0;
}
.hero__content,
.hero__heading,
.hero__summary *,
.hero__featured_content_title,
.hero__featured_content_body {
  color: #fff;
}
.hero__content {
  position: relative;
  z-index: auto;
  z-index: 1;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  width: 92%;
  max-width: 1180px;
  flex-direction: column;
  justify-content: center;
  padding: 3.236rem 0 6.472rem;
}
.hero__heading {
  width: 100%;
  text-transform: uppercase;
  line-height: 0.9;
  font-family: 'tandelle', sans-serif;
  font-weight: 400;
}
.hero__heading {
  font-size: 40px;
}
@media screen and (min-width: 320px) {
  .hero__heading {
    font-size: calc(12.658227848101266vw - 0.5063291139240533px);
  }
}
@media screen and (min-width: 1900px) {
  .hero__heading {
    font-size: 240px;
  }
}
.hero__summary {
  width: 100%;
  text-shadow: 0 1px 3px rgba(24,24,24,0.5);
}
* + .hero__summary {
  margin-top: 1.618rem;
}
.hero__summary * {
  font-size: 1.265625rem;
  font-size: var(--fz-ratio--two);
  width: 88ch;
}
@media only screen and (min-width: 681px) {
  .hero__summary * {
    font-size: 1.47015625rem;
    font-size: var(--fz-ratio--two);
  }
}
@media only screen and (min-width: 1281px) {
  .hero__summary * {
    font-size: 1.69rem;
    font-size: var(--fz-ratio--two);
  }
}
.hero__featured_content__list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.hero__featured_content__item,
.hero__featured_content .card {
  flex: 0 0 100%;
}
@media only screen and (min-width: 681px) {
  .hero__featured_content__item,
  .hero__featured_content .card {
    flex: 0 0 48%;
  }
}
@media only screen and (min-width: 1081px) {
  .hero__featured_content__item,
  .hero__featured_content .card {
    flex: 0 0 30.666666666666668%;
  }
}
@media only screen and (min-width: 681px) and (max-width: 1080px) {
  .hero__featured_content__item:nth-child(even),
  .hero__featured_content .card:nth-child(even) {
    margin-left: 4%;
  }
}
@media only screen and (min-width: 1081px) {
  .hero__featured_content__item:nth-child(3n+2),
  .hero__featured_content .card:nth-child(3n+2),
  .hero__featured_content__item:nth-child(3n+3),
  .hero__featured_content .card:nth-child(3n+3) {
    margin-left: 4%;
  }
}
@media only screen and (max-width: 680px) {
  .hero__featured_content__item:nth-child(n+2),
  .hero__featured_content .card:nth-child(n+2) {
    margin-top: 8%;
  }
}
@media only screen and (max-width: 1080px) {
  .hero__featured_content__item:nth-child(n+3),
  .hero__featured_content .card:nth-child(n+3) {
    margin-top: 8%;
  }
}
@media only screen and (min-width: 1081px) {
  .hero__featured_content__item:nth-child(n+4),
  .hero__featured_content .card:nth-child(n+4) {
    margin-top: 8%;
  }
}
@media only screen and (min-width: 681px) {
  .hero__featured_content__item:last-child,
  .hero__featured_content .card:last-child {
    margin-right: auto;
  }
}
.hero__featured_content__list {
  width: 100%;
  max-width: 1180px;
  margin: 4.854rem auto 0;
}
.hero__featured_content__item {
  text-shadow: 0 1px 3px rgba(24,24,24,0.5);
}
.hero__featured_content__item:first-child {
  margin-left: auto;
}
.hero__featured_content__anchor {
/*
        the below line should be commented out because we have exceeded most browsers'
        will-change budget allowance. we can fix this by only using one or
        two properties as a default and then specifying properties every time
        we call the mixin, but at present removing default properties from this
        mixin causes many of the site's styles to break, and actually commenting
        out the line also causes styles to break.
    */
  transition-property: outline-offset, background-color, box-shadow, color, opacity;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.hero__featured_content__anchor:active .hero__featured_content_icon,
.hero__featured_content__anchor:focus .hero__featured_content_icon,
.hero__featured_content__anchor:hover .hero__featured_content_icon {
  transform: translateY(-0.269666666666667rem) scale(1.1);
}
.hero__featured_content_title {
  max-width: 28ch;
  text-align: center;
  line-height: 1.4;
  font-size: 1.125rem;
  font-size: var(--fz-ratio--one);
}
* + .hero__featured_content_title {
  margin-top: 1.2135rem;
}
@media only screen and (min-width: 681px) {
  .hero__featured_content_title {
    font-size: 1.2125rem;
    font-size: var(--fz-ratio--one);
  }
}
@media only screen and (min-width: 1281px) {
  .hero__featured_content_title {
    font-size: 1.3rem;
    font-size: var(--fz-ratio--one);
  }
}
.hero__featured_content_icon {
/*
        the below line should be commented out because we have exceeded most browsers'
        will-change budget allowance. we can fix this by only using one or
        two properties as a default and then specifying properties every time
        we call the mixin, but at present removing default properties from this
        mixin causes many of the site's styles to break, and actually commenting
        out the line also causes styles to break.
    */
  transition-property: outline-offset, background-color, box-shadow, color, opacity;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  height: 4rem;
  width: 4rem;
}
.hero__featured_content_body {
  max-width: 44ch;
  text-align: center;
}
* + .hero__featured_content_body {
  margin-top: 0.4045rem;
}
.icon_strip {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-items: space-between;
  max-width: 100%;
}
* + .icon_strip {
  margin-top: 1.618rem;
}
.icon_strip__item {
  flex: 0 0 49%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media only screen and (min-width: 481px) {
  .icon_strip__item {
    flex: 0 0 32%;
  }
}
@media only screen and (min-width: 681px) {
  .icon_strip__item {
    flex: 0 0 23.5%;
  }
}
@media only screen and (min-width: 1081px) {
  .icon_strip__item {
    flex: 0 0 32%;
  }
}
@media only screen and (max-width: 480px) {
  .icon_strip__item:nth-child(n+3) {
    margin-top: 1.618rem;
  }
}
@media only screen and (max-width: 480px) {
  .icon_strip__item:nth-child(even) {
    margin-left: 2%;
  }
}
@media only screen and (min-width: 481px) and (max-width: 680px) {
  .icon_strip__item:nth-child(n+4) {
    margin-top: 1.618rem;
  }
}
@media only screen and (min-width: 1081px) {
  .icon_strip__item:nth-child(n+4) {
    margin-top: 1.618rem;
  }
}
@media only screen and (min-width: 681px) and (max-width: 1280px) {
  .icon_strip__item:nth-child(n+5) {
    margin-top: 1.618rem;
  }
}
@media only screen and (min-width: 481px) and (max-width: 680px) {
  .icon_strip__item:nth-child(3n+2),
  .icon_strip__item:nth-child(3n+3) {
    margin-left: 2%;
  }
}
@media only screen and (min-width: 1081px) {
  .icon_strip__item:nth-child(3n+2),
  .icon_strip__item:nth-child(3n+3) {
    margin-left: 2%;
  }
}
@media only screen and (min-width: 681px) and (max-width: 1080px) {
  .icon_strip__item:nth-child(4n+2),
  .icon_strip__item:nth-child(4n+3),
  .icon_strip__item:nth-child(4n+4) {
    margin-left: 2%;
  }
}
.icon_strip__item:last-child {
  margin-left: auto;
}
.icon_strip__label {
  width: auto;
  max-width: 100%;
  text-align: center;
  font-size: 0.888888888888889rem;
  font-size: var(--fz-ratio--minus-one);
}
* + .icon_strip__label {
  margin-top: 0.539333333333333rem;
}
@media only screen and (min-width: 681px) {
  .icon_strip__label {
    font-size: 0.824742268041237rem;
    font-size: var(--fz-ratio--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .icon_strip__label {
    font-size: 0.769230769230769rem;
    font-size: var(--fz-ratio--minus-one);
  }
}
.navigation__icon--hamburger:hover .icon__hamburger-01 {
  transform: translateX(4px) translateY(-6px);
}
.navigation__icon--hamburger:hover .icon__hamburger-02 {
  transform: translateX(4px) translateY(0);
}
.navigation__icon--hamburger:hover .icon__hamburger-03 {
  transform: translateX(4px) translateY(6px);
}
.icon__hamburger-01,
.icon__hamburger-02,
.icon__hamburger-03 {
/*
        the below line should be commented out because we have exceeded most browsers'
        will-change budget allowance. we can fix this by only using one or
        two properties as a default and then specifying properties every time
        we call the mixin, but at present removing default properties from this
        mixin causes many of the site's styles to break, and actually commenting
        out the line also causes styles to break.
    */
  transition-property: outline-offset, opacity, transform;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  width: 38px;
  transform-origin: 50% 50%;
  transform: translateX(4px);
}
.navigation_menu .navigation--show .icon__hamburger-01,
.navigation_menu .navigation--show .icon__hamburger-02,
.navigation_menu .navigation--show .icon__hamburger-03 {
  width: 46px;
}
.navigation_menu .navigation--show .icon__hamburger-01 {
  transform: translateX(-12px) translateY(-12px) rotate(-45deg);
}
.navigation_menu .navigation--show .icon__hamburger-02 {
  transform: translateY(0);
  opacity: 0;
}
.navigation_menu .navigation--show .icon__hamburger-03 {
  transform: translateX(-12px) translateY(12px) rotate(45deg);
}
.navigation_menu .navigation--show:hover .icon__hamburger-01,
.navigation_menu .navigation--show:hover .icon__hamburger-02,
.navigation_menu .navigation--show:hover .icon__hamburger-03 {
  width: 38px;
}
.navigation_menu .navigation--show:hover .icon__hamburger-01 {
  transform: translateX(4px) translateY(-17px);
}
.navigation_menu .navigation--show:hover .icon__hamburger-02 {
  transform: translateX(4px) translateY(0);
}
.navigation_menu .navigation--show:hover .icon__hamburger-03 {
  transform: translateX(4px) translateY(17px);
}
.navigation__icon--search .icon__search {
/*
        the below line should be commented out because we have exceeded most browsers'
        will-change budget allowance. we can fix this by only using one or
        two properties as a default and then specifying properties every time
        we call the mixin, but at present removing default properties from this
        mixin causes many of the site's styles to break, and actually commenting
        out the line also causes styles to break.
    */
  transition-property: outline-offset, transform;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  transform-origin: 50% 50%;
}
.global_search_is_visible .navigation__icon--search .icon__search {
  transform-origin: 50% 50%;
  transform: rotate(45deg);
}
.navigation__icon--search:hover .icon__search {
  transform-origin: 50% 50%;
  transform: rotate(45deg);
}
.icon__close,
.icon__hamburger,
.icon__search {
  height: 2rem;
  width: 2rem;
}
.icon__fill--light {
  fill: #fff;
}
.icon__fill--dark {
  fill: #333;
}
.icon__stroke--light {
  stroke: #fff;
  stroke-width: 2;
}
.icon__stroke--dark {
  stroke: #333;
  stroke-width: 2;
}
.icon__accordion-plus {
  fill: #555;
}
.image_chooser {
  max-width: 92%;
  max-height: 44rem;
  margin-right: auto;
  margin-left: auto;
}
* + .image_chooser {
  margin-top: 3.236rem;
}
.image_grid,
.linked_image_grid {
  width: 100%;
}
* + .image_grid,
* + .linked_image_grid {
  margin-top: 3.236rem;
}
.image_grid__list,
.linked_image_grid__list {
  display: flex;
  margin-right: auto;
  margin-left: auto;
  width: 92%;
  max-width: 1600px;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-start;
  list-style-type: none;
}
.image_grid__item,
.linked_image_grid__item {
  flex: 0 0 100%;
}
@media only screen and (min-width: 481px) {
  .image_grid__item,
  .linked_image_grid__item {
    flex: 0 0 49%;
  }
}
@media only screen and (min-width: 681px) {
  .image_grid__item,
  .linked_image_grid__item {
    flex: 0 0 32%;
  }
}
@media only screen and (min-width: 881px) {
  .image_grid__item,
  .linked_image_grid__item {
    flex: 0 0 23.5%;
  }
}
@media only screen and (max-width: 480px) {
  .image_grid__item:nth-child(n+2),
  .linked_image_grid__item:nth-child(n+2) {
    margin-top: 1.618rem;
  }
}
@media only screen and (min-width: 481px) and (max-width: 680px) {
  .image_grid__item:nth-child(n+3),
  .linked_image_grid__item:nth-child(n+3) {
    margin-top: 2%;
  }
}
@media only screen and (min-width: 681px) and (max-width: 880px) {
  .image_grid__item:nth-child(n+4),
  .linked_image_grid__item:nth-child(n+4) {
    margin-top: 2%;
  }
}
@media only screen and (min-width: 881px) {
  .image_grid__item:nth-child(n+5),
  .linked_image_grid__item:nth-child(n+5) {
    margin-top: 2%;
  }
}
@media only screen and (min-width: 481px) and (max-width: 680px) {
  .image_grid__item:nth-child(even),
  .linked_image_grid__item:nth-child(even) {
    margin-left: 2%;
  }
}
@media only screen and (min-width: 681px) and (max-width: 880px) {
  .image_grid__item:nth-child(3n+2),
  .linked_image_grid__item:nth-child(3n+2),
  .image_grid__item:nth-child(3n+3),
  .linked_image_grid__item:nth-child(3n+3) {
    margin-left: 2%;
  }
}
@media only screen and (min-width: 881px) {
  .image_grid__item:nth-child(4n+2),
  .linked_image_grid__item:nth-child(4n+2),
  .image_grid__item:nth-child(4n+3),
  .linked_image_grid__item:nth-child(4n+3),
  .image_grid__item:nth-child(4n+4),
  .linked_image_grid__item:nth-child(4n+4) {
    margin-left: 2%;
  }
}
.image_grid__anchor,
.linked_image_grid__anchor {
  display: flex;
}
.image_grid__anchor img,
.linked_image_grid__anchor img {
  margin: auto;
}
.linked_image_chooser {
  display: block;
}
* + .linked_image_chooser {
  margin-top: 3.236rem;
}
.linked_image_chooser__image {
  max-width: 92%;
  margin-right: auto;
  margin-left: auto;
}
.location_details {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: 1600px;
  font-size: 1rem;
  width: 92%;
}
* + .location_details {
  margin-top: 3.236rem;
}
.location_details a:not(.button),
.location_details a:not(.button):visited {
  box-shadow: inset 0 -1px rgba(85,85,85,0.3);
  color: #555;
}
.location_details a:not(.button):active,
.location_details a:not(.button):focus,
.location_details a:not(.button):hover {
  box-shadow: inset 0 -0.2em rgba(85,85,85,0.6);
  color: #333;
}
.location_details .rich-text {
  margin-top: 4.854rem;
}
.location_details__heading {
  width: 100%;
  margin: 0 auto;
  font-size: 1.601806640625rem;
  font-size: var(--fz-ratio--four);
}
@media only screen and (min-width: 681px) {
  .location_details__heading {
    font-size: 2.161359399414062rem;
    font-size: var(--fz-ratio--four);
  }
}
@media only screen and (min-width: 1281px) {
  .location_details__heading {
    font-size: 2.856100000000001rem;
    font-size: var(--fz-ratio--four);
  }
}
.location_details__map {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 32rem;
}
@media only screen and (min-width: 881px) {
  .location_details__map {
    flex-direction: row;
  }
}
* + .location_details__map {
  margin-top: 1.618rem;
}
.location_details__map--centered {
  display: flex;
  justify-content: center;
}
.location_details__map--centered .location_details__google_map {
  max-width: 1600px;
  margin: 0 auto;
}
.location_details__map_and_details {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 32rem;
}
@media only screen and (min-width: 1081px) {
  .location_details__map_and_details {
    flex-direction: row;
  }
}
* + .location_details__map_and_details {
  margin-top: 1.618rem;
}
.location_details__map_and_details--centered {
  display: flex;
  justify-content: center;
}
.location_details__map_and_details--centered .location_details__google_map {
  max-width: 1600px;
  margin: 0 auto;
}
.location_details__title {
  font-size: 1rem;
  text-transform: none;
}
.location_details__title + * {
  margin-top: 0.269666666666667rem;
}
* + .location_details__title {
  margin-top: 0.809rem;
}
.location_details__google_map {
  overflow: hidden;
  box-shadow: 0 12px 17px -5px rgba(31,31,31,0.2), 0 35px 110px 0px rgba(31,31,31,0.1);
}
@media only screen and (min-width: 1081px) {
  .location_details__google_map {
    flex: 1 1 auto;
  }
}
.location_details__details {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  flex: none;
  width: auto;
}
@media only screen and (min-width: 481px) {
  .location_details__details {
    flex-direction: row;
  }
}
@media only screen and (min-width: 1081px) {
  .location_details__details {
    flex-direction: column;
    justify-content: center;
  }
}
@media only screen and (min-width: 1081px) {
  * + .location_details__details {
    margin-left: 2.427rem;
  }
}
@media only screen and (max-width: 1080px) {
  * + .location_details__details {
    margin-top: 1.618rem;
  }
}
.location_details__contact {
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 1081px) {
  .location_details__contact {
    flex: 0 0 auto;
  }
}
* + .location_details__button,
* + .location_details__electronic {
  margin-top: 1.2135rem;
}
@media only screen and (min-width: 481px) and (max-width: 1080px) {
  .location_details__contact,
  .location_details__times {
    width: 50%;
  }
}
@media only screen and (max-width: 480px) {
  * + .location_details__times {
    margin-top: 1.2135rem;
  }
}
@media only screen and (min-width: 1081px) {
  * + .location_details__times {
    margin-top: 1.2135rem;
  }
}
.location_details__times__list {
  font-size: 0.888888888888889rem;
  font-size: var(--fz-ratio--minus-one);
}
@media only screen and (min-width: 681px) {
  .location_details__times__list {
    font-size: 0.824742268041237rem;
    font-size: var(--fz-ratio--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .location_details__times__list {
    font-size: 0.769230769230769rem;
    font-size: var(--fz-ratio--minus-one);
  }
}
.location_details__times__item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.location_details__times__day_name {
  flex: 0 0 3.8rem;
  font-weight: 700;
}
.location_details__times__opening_times {
  flex: 0 0 auto;
}
* + .location_details__times__opening_times {
  margin-left: 0.809rem;
}
.location_details__times__opening_times__guide {
  color: rgba(119,119,119,0.6);
}
.location_details__address,
.location_details__electronic {
  font-size: 0.888888888888889rem;
  font-size: var(--fz-ratio--minus-one);
}
@media only screen and (min-width: 681px) {
  .location_details__address,
  .location_details__electronic {
    font-size: 0.824742268041237rem;
    font-size: var(--fz-ratio--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .location_details__address,
  .location_details__electronic {
    font-size: 0.769230769230769rem;
    font-size: var(--fz-ratio--minus-one);
  }
}
.location_details__title {
  display: block;
}
.location_details__electronic .location_details__title {
  font-weight: 700;
}
.icon__logo {
  height: auto;
  max-height: 100%;
  width: 100%;
  max-width: 100%;
}
#mc_embed_signup {
  border-radius: 5px;
  margin: 2.427rem auto 0;
  padding: 1.618rem 4vw;
}
#mc_embed_signup .mc-field-group {
  margin: 0.20225rem 0;
  display: flex;
  flex-direction: column;
}
#mc_embed_signup .indicates-required {
  margin-bottom: 1.618rem;
}
#mc_embed_signup .clear {
  margin-top: 1.618rem;
}
.page_content #mc_embed_signup {
  background: #e5e9f1;
}
.membership_cta {
  width: 92%;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
}
* + .membership_cta {
  margin-top: 3.236rem;
}
.membership_cta__inner {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  box-shadow: 0 12px 17px -5px rgba(31,31,31,0.2), 0 35px 110px 0px rgba(31,31,31,0.1);
}
@media only screen and (min-width: 881px) {
  .membership_cta__inner {
    flex-direction: row;
  }
}
@media only screen and (min-width: 881px) {
  .membership_cta__image,
  .membership_cta__information {
    flex: 0 0 50%;
  }
}
.membership_cta__image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media only screen and (max-width: 880px) {
  .membership_cta__image {
    padding-bottom: 50%;
  }
}
.membership_cta__information {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: #fff;
  padding: 1.618rem 4vw;
}
@media only screen and (min-width: 881px) {
  .membership_cta__information {
    padding: 3.236rem 1.618rem;
  }
}
.membership_cta__summary {
  width: 100%;
  max-width: 78ch;
  word-break: break-all;
}
* + .membership_cta__summary {
  margin-top: 0.539333333333333rem;
}
.membership_pricing {
  display: block;
  margin-right: auto;
  margin-left: auto;
  width: 92%;
  max-width: 1180px;
}
* + .membership_pricing {
  margin-top: 3.236rem;
}
.membership_pricing__small_viewport_hint {
  display: block;
  background-color: #333;
  padding: 0.20225rem 0.809rem 0.269666666666667rem;
  font-size: 0.888888888888889rem;
  font-size: var(--fz-ratio--minus-one);
  color: #fff;
}
@media only screen and (min-width: 881px) {
  .membership_pricing__small_viewport_hint {
    display: none;
  }
}
@media only screen and (min-width: 681px) {
  .membership_pricing__small_viewport_hint {
    font-size: 0.824742268041237rem;
    font-size: var(--fz-ratio--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .membership_pricing__small_viewport_hint {
    font-size: 0.769230769230769rem;
    font-size: var(--fz-ratio--minus-one);
  }
}
.membership_pricing__table_wrap {
  width: 100%;
}
@media only screen and (max-width: 1080px) {
  .membership_pricing__table_wrap {
    overflow-x: auto;
  }
}
.membership_pricing__table {
  width: 100%;
  table-layout: fixed;
  box-shadow: 0 12px 17px -5px rgba(31,31,31,0.15), 0 35px 110px 0px rgba(31,31,31,0.1);
  background-color: #fff;
}
@media only screen and (max-width: 1080px) {
  .membership_pricing__table {
    min-width: 600px;
  }
}
.membership_pricing__title {
  font-weight: 700;
  border-bottom: 1px solid rgba(0,0,0,0.15);
}
.membership_pricing__title_cell {
  text-align: center;
  padding: 1.2135rem;
}
.membership_pricing__content_row {
  border-bottom: 1px solid rgba(0,0,0,0.15);
}
.membership_pricing__content_row:first-child .membership_pricing__content_cell {
  padding-top: 1.2135rem;
}
.membership_pricing__content_row:last-child .membership_pricing__content_cell {
  padding-bottom: 1.2135rem;
}
.membership_pricing__content_cell {
  text-align: center;
  padding: 0.809rem 1.2135rem;
}
.news_summary,
.events_summary {
  display: block;
  margin-right: auto;
  margin-left: auto;
  width: 92%;
  max-width: 1600px;
}
* + .news_summary,
* + .events_summary {
  margin-top: 3.236rem;
}
* + .news_summary > *,
* + .events_summary > * {
  margin-top: 1.618rem;
}
.news_summary__heading,
.events_summary__heading {
  width: 100%;
  font-size: 1.423828125rem;
  font-size: var(--fz-ratio--three);
}
@media only screen and (min-width: 681px) {
  .news_summary__heading,
  .events_summary__heading {
    font-size: 1.782564453125rem;
    font-size: var(--fz-ratio--three);
  }
}
@media only screen and (min-width: 1281px) {
  .news_summary__heading,
  .events_summary__heading {
    font-size: 2.197000000000001rem;
    font-size: var(--fz-ratio--three);
  }
}
.news_summary__list,
.events_summary__list {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  max-width: 1600px;
}
* + .news_summary__list,
* + .events_summary__list {
  margin-top: 1.618rem;
}
.news_summary__item,
.events_summary__item {
  flex: 0 0 100%;
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 681px) {
  .news_summary__item,
  .events_summary__item {
    flex: 0 0 49%;
  }
}
@media only screen and (min-width: 1281px) {
  .news_summary__item,
  .events_summary__item {
    flex: 0 0 32%;
  }
}
@media only screen and (min-width: 681px) {
  .news_summary__item,
  .events_summary__item {
    flex-direction: row;
    align-items: start;
  }
}
* + .news_summary__item,
* + .events_summary__item {
  margin-top: 1.618rem;
}
@media only screen and (min-width: 681px) {
  * + .news_summary__item,
  * + .events_summary__item {
    margin-top: 0;
  }
}
@media only screen and (min-width: 681px) and (max-width: 1280px) {
  .news_summary__item:nth-child(even),
  .events_summary__item:nth-child(even) {
    margin-left: 2%;
  }
}
@media only screen and (min-width: 681px) and (max-width: 1280px) {
  .news_summary__item:nth-child(n+3),
  .events_summary__item:nth-child(n+3) {
    margin-top: 1.618rem;
  }
}
@media only screen and (min-width: 1281px) {
  .news_summary__item:nth-child(3n+2),
  .events_summary__item:nth-child(3n+2),
  .news_summary__item:nth-child(3n+3),
  .events_summary__item:nth-child(3n+3) {
    margin-left: 2%;
  }
}
@media only screen and (min-width: 1281px) {
  .news_summary__item:nth-child(n+4),
  .events_summary__item:nth-child(n+4) {
    margin-top: 1.618rem;
  }
}
@media only screen and (min-width: 681px) {
  .news_summary__item:last-child,
  .events_summary__item:last-child {
    margin-right: auto;
  }
}
@media only screen and (min-width: 481px) and (max-width: 1080px) {
  .news_summary__image_link,
  .events_summary__image_link {
    flex: 0 0 8rem;
  }
}
@media only screen and (min-width: 1081px) {
  .news_summary__image_link,
  .events_summary__image_link {
    flex: 0 0 10rem;
  }
}
.news_summary__image_link:hover,
.events_summary__image_link:hover {
  opacity: 0.8;
}
@media only screen and (min-width: 1081px) {
  .news_summary__heading_and_summary,
  .events_summary__heading_and_summary {
    flex: 1;
  }
}
@media only screen and (max-width: 480px) {
  * + .news_summary__heading_and_summary,
  * + .events_summary__heading_and_summary {
    margin-top: 1.618rem;
  }
}
@media only screen and (min-width: 481px) {
  * + .news_summary__heading_and_summary,
  * + .events_summary__heading_and_summary {
    margin-left: 1.618rem;
  }
}
.news_summary__item_heading,
.events_summary__item_heading {
  font-size: 0.888888888888889rem;
  font-size: var(--fz-ratio--minus-one);
}
@media only screen and (min-width: 681px) {
  .news_summary__item_heading,
  .events_summary__item_heading {
    font-size: 0.824742268041237rem;
    font-size: var(--fz-ratio--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .news_summary__item_heading,
  .events_summary__item_heading {
    font-size: 0.769230769230769rem;
    font-size: var(--fz-ratio--minus-one);
  }
}
.news_summary__item_heading a,
.events_summary__item_heading a {
  color: #333;
}
.news_summary__item_summary,
.events_summary__item_summary {
  width: 100%;
  max-width: 78ch;
  font-size: 0.888888888888889rem;
  font-size: var(--fz-ratio--minus-one);
}
* + .news_summary__item_summary,
* + .events_summary__item_summary {
  margin-top: 0.539333333333333rem;
}
@media only screen and (min-width: 681px) {
  .news_summary__item_summary,
  .events_summary__item_summary {
    font-size: 0.824742268041237rem;
    font-size: var(--fz-ratio--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .news_summary__item_summary,
  .events_summary__item_summary {
    font-size: 0.769230769230769rem;
    font-size: var(--fz-ratio--minus-one);
  }
}
.news_summary__item_further_anchor,
.events_summary__item_further_anchor {
  font-weight: 700;
  font-size: 0.888888888888889rem;
  font-size: var(--fz-ratio--minus-one);
}
.news_summary__item_further_anchor,
.events_summary__item_further_anchor,
.news_summary__item_further_anchor:visited,
.events_summary__item_further_anchor:visited {
  box-shadow: inset 0 -1px rgba(85,85,85,0.3);
  color: #555;
}
.news_summary__item_further_anchor:active,
.events_summary__item_further_anchor:active,
.news_summary__item_further_anchor:focus,
.events_summary__item_further_anchor:focus,
.news_summary__item_further_anchor:hover,
.events_summary__item_further_anchor:hover {
  box-shadow: inset 0 -0.2em rgba(85,85,85,0.6);
  color: #333;
}
@media only screen and (min-width: 681px) {
  .news_summary__item_further_anchor,
  .events_summary__item_further_anchor {
    font-size: 0.824742268041237rem;
    font-size: var(--fz-ratio--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .news_summary__item_further_anchor,
  .events_summary__item_further_anchor {
    font-size: 0.769230769230769rem;
    font-size: var(--fz-ratio--minus-one);
  }
}
.page_chooser {
  margin-right: auto;
  margin-left: auto;
  width: 92%;
  max-width: 1600px;
}
* + .page_chooser {
  margin-top: 3.236rem;
}
.page_chooser__inner {
/*
        the below line should be commented out because we have exceeded most browsers'
        will-change budget allowance. we can fix this by only using one or
        two properties as a default and then specifying properties every time
        we call the mixin, but at present removing default properties from this
        mixin causes many of the site's styles to break, and actually commenting
        out the line also causes styles to break.
    */
  transition-property: outline-offset, background-color, box-shadow, color, opacity;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 12px 17px -5px rgba(31,31,31,0.2), 0 35px 110px 0px rgba(31,31,31,0.1);
  width: 100%;
  background-color: #fff;
  padding: 1.618rem 4%;
  text-align: center;
}
.page_chooser__introduction {
  display: block;
  text-transform: uppercase;
  font-size: 0.888888888888889rem;
  font-size: var(--fz-ratio--minus-one);
  color: #555;
}
* + .page_chooser__introduction {
  margin-top: 0.809rem;
}
@media only screen and (min-width: 681px) {
  .page_chooser__introduction {
    font-size: 0.824742268041237rem;
    font-size: var(--fz-ratio--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .page_chooser__introduction {
    font-size: 0.769230769230769rem;
    font-size: var(--fz-ratio--minus-one);
  }
}
* + .page_chooser__heading {
  margin-top: 0.539333333333333rem;
}
.page_chooser__heading a,
.page_chooser__heading a:visited {
  color: #555;
}
.page_chooser__heading a:active,
.page_chooser__heading a:focus,
.page_chooser__heading a:hover {
  color: #181818;
}
.page_chooser__teaser {
  width: 68ch;
}
.page_chooser__button {
  margin-top: 1.2135rem;
/*
        the below line should be commented out because we have exceeded most browsers'
        will-change budget allowance. we can fix this by only using one or
        two properties as a default and then specifying properties every time
        we call the mixin, but at present removing default properties from this
        mixin causes many of the site's styles to break, and actually commenting
        out the line also causes styles to break.
    */
  transition-property: outline-offset, background-color, box-shadow, color, opacity;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}
.page_heading {
  display: block;
  margin-right: auto;
  margin-left: auto;
  width: 92%;
  max-width: 1600px;
}
.page_heading__title,
.page_heading__summary {
  font-family: 'tandelle', sans-serif;
}
.page_heading__summary {
  margin-top: 0;
  font-size: 1.601806640625rem;
  font-size: var(--fz-ratio--four);
}
@media only screen and (min-width: 681px) {
  .page_heading__summary {
    font-size: 2.161359399414062rem;
    font-size: var(--fz-ratio--four);
  }
}
@media only screen and (min-width: 1281px) {
  .page_heading__summary {
    font-size: 2.856100000000001rem;
    font-size: var(--fz-ratio--four);
  }
}
.page_navigation {
  margin-right: auto;
  margin-left: auto;
  width: 92%;
  max-width: 1600px;
}
* + .page_navigation {
  margin-top: 1.618rem;
}
* + .hero_image + .page_navigation {
  margin-top: 3.236rem;
}
.page_navigation__list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  border-top: 1px solid rgba(85,85,85,0.35);
  border-bottom: 1px solid rgba(85,85,85,0.35);
  padding: 0.809rem 0;
}
.page_navigation__item {
  flex: 0 0 auto;
  display: inline-block;
  margin: 0.539333333333333rem 1.077588rem;
  line-height: 1.4;
}
.page_navigation__anchor {
  padding: 0.269666666666667rem 0;
  font-weight: 700;
}
.page_navigation__anchor,
.page_navigation__anchor:link,
.page_navigation__anchor:visited {
  box-shadow: inset 0 -1.5px rgba(85,85,85,0.175);
  color: #555;
}
.page_navigation__anchor:hover {
  box-shadow: inset 0 -3px rgba(24,24,24,0.6);
  color: #181818;
}
.page_navigation__anchor:active,
.page_navigation__anchor:focus {
  box-shadow: inset 0 -3px rgba(24,24,24,0.6);
  color: #181818;
}
.pagination {
  display: block;
  width: 100%;
}
.pagination__list {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  width: 92%;
  max-width: 1900px;
  margin-right: auto;
  margin-left: auto;
}
.pagination__item {
  flex: 0 0 auto;
}
* + .pagination__item {
  margin-left: 0.539333333333333rem;
}
.pagination__item--leading,
.pagination__item--trailing {
  display: none;
}
@media only screen and (min-width: 481px) {
  .pagination__item--leading,
  .pagination__item--trailing {
    display: flex;
  }
}
.pagination__number {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.618rem;
  max-height: 1.618rem;
  width: 1.618rem;
  max-width: 1.618rem;
  border-radius: 5px;
  padding: 0.539333333333333rem;
  color: #333;
}
@media only screen and (min-width: 681px) {
  .pagination__number {
    height: 2.695588rem;
    max-height: 2.695588rem;
    width: 2.695588rem;
    max-width: 2.695588rem;
  }
}
.pagination__number--current {
  background-color: #e5e9f1;
  box-shadow: 0 1px 0.02em rgba(31,31,31,0.2);
}
.pagination__number--anchor {
  box-shadow: 0 2px 0.04em rgba(31,31,31,0.2);
  background-color: #fff;
}
.pagination__number--anchor:active,
.pagination__number--anchor:focus,
.pagination__number--anchor:hover {
  box-shadow: 0 4px 0.24em rgba(31,31,31,0.2);
  color: #666;
}
.pagination__number--previous .pagination__arrow_icon {
  transform: rotate(180deg);
}
.pagination__arrow_icon {
  max-height: 1.077588rem;
  max-width: 1.077588rem;
}
.pagination__arrow_icon_path {
  fill: #333;
}
.pagination__anchor:active .pagination__arrow_icon_path,
.pagination__anchor:focus .pagination__arrow_icon_path,
.pagination__anchor:hover .pagination__arrow_icon_path {
  fill: #666;
}
.panel {
  position: relative;
  z-index: auto;
  width: 100%;
  background-color: transparent;
  padding: 4.854rem 0;
}
.panel.panel--cl-first,
.panel.panel--cl-second,
.panel.panel--cl-third,
.panel.panel--cl-fourth {
  color: #fff;
}
.panel.panel--cl-first {
  background-color: #e52321;
}
.panel.panel--cl-second {
  background-color: #fc0;
}
.panel.panel--cl-third {
  background-color: #aac91b;
}
.panel.panel--cl-fourth {
  background-color: #509ece;
}
.panel h1 svg,
.panel h2 svg,
.panel h3 svg,
.panel h4 svg,
.panel h5 svg {
  height: 4.5rem;
  fill: #fff;
}
.panel__background {
  z-index: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  height: 100%;
  width: 100%;
  opacity: 0.16;
}
.panel__background-image {
  position: absolute;
  top: 0;
  right: 0;
  width: auto;
  height: 1200px;
  user-select: none;
  pointer-events: none;
  z-index: 0;
}
.panel__background-image--bottom-left {
  top: auto;
  bottom: 0;
  left: 0;
}
.panel__background-image--top-right {
  top: 0;
  right: 0;
}
.panel__inner {
  position: relative;
  z-index: auto;
  margin-right: auto;
  margin-left: auto;
  height: 100%;
  width: 92%;
  max-width: 1180px;
  text-align: center;
}
* + .panel__inner {
  margin-top: 3.236rem;
}
.panel__item {
/*
        the below line should be commented out because we have exceeded most browsers'
        will-change budget allowance. we can fix this by only using one or
        two properties as a default and then specifying properties every time
        we call the mixin, but at present removing default properties from this
        mixin causes many of the site's styles to break, and actually commenting
        out the line also causes styles to break.
    */
  transition-property: outline-offset, background-color, box-shadow, color, opacity;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  position: relative;
  z-index: auto;
  box-shadow: 0 12px 17px -5px rgba(31,31,31,0.2), 0 35px 110px 0px rgba(31,31,31,0.1);
  background-color: #fff;
}
* + .panel__item {
  margin-top: 4.854rem;
}
.panel--text .panel__item {
  background-color: #fff;
  padding: 3.236rem 4vw;
}
.panel--text .panel__item p {
  margin-right: auto;
  margin-left: auto;
  max-width: 58ch;
  text-align: center;
  font-size: 1.125rem;
  font-size: var(--fz-ratio--one);
}
@media only screen and (min-width: 681px) {
  .panel--text .panel__item p {
    font-size: 1.2125rem;
    font-size: var(--fz-ratio--one);
  }
}
@media only screen and (min-width: 1281px) {
  .panel--text .panel__item p {
    font-size: 1.3rem;
    font-size: var(--fz-ratio--one);
  }
}
.panel__image_list {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
* + .panel__image_list {
  margin-top: 1.618rem;
}
.panel__image_item {
  flex: 0 0 49%;
  overflow: hidden;
  height: 100%;
}
@media only screen and (min-width: 881px) {
  .panel__image_item {
    flex: 0 0 32%;
  }
}
@media only screen and (max-width: 480px) {
  * + .panel__image_item {
    margin-top: 4%;
  }
}
@media only screen and (max-width: 480px) {
  .panel__image_item:first-child {
    flex: 0 0 100%;
  }
}
@media only screen and (max-width: 480px) {
  .panel__image_item:nth-child(n+2) {
    margin-top: 2%;
  }
}
@media only screen and (min-width: 481px) and (max-width: 880px) {
  .panel__image_item:nth-child(n+3) {
    margin-top: 2%;
  }
}
@media only screen and (min-width: 881px) {
  .panel__image_item:nth-child(n+4) {
    margin-top: 2%;
  }
}
@media only screen and (min-width: 481px) and (max-width: 880px) {
  .panel__image_item:nth-child(even) {
    margin-left: 2%;
  }
}
@media only screen and (min-width: 881px) {
  .panel__image_item:nth-child(3n+2),
  .panel__image_item:nth-child(3n+3) {
    margin-left: 2%;
  }
}
@media only screen and (min-width: 481px) {
  .panel__image_item:first-child {
    margin-left: auto;
  }
}
@media only screen and (min-width: 481px) {
  .panel__image_item:last-child {
    margin-right: auto;
  }
}
.panel__image_item * {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}
.panel--intro {
  padding: 0;
  padding-top: 3.236rem;
  padding-bottom: 1.618rem;
}
.panel--intro p {
  font-size: 1.265625rem;
  font-size: var(--fz-ratio--two);
}
@media only screen and (min-width: 681px) {
  .panel--intro p {
    font-size: 1.47015625rem;
    font-size: var(--fz-ratio--two);
  }
}
@media only screen and (min-width: 1281px) {
  .panel--intro p {
    font-size: 1.69rem;
    font-size: var(--fz-ratio--two);
  }
}
.panel--alternating {
  width: 100%;
}
.panel--alternating .panel__heading-container {
  max-width: 1180px;
}
.panel--alternating .panel__inner {
  display: flex;
  flex-direction: column;
  max-width: 1180px;
}
.panel--alternating .panel__item {
  display: flex;
  flex-direction: column;
  background-color: #fff;
}
@media only screen and (min-width: 881px) {
  .panel--alternating .panel__item {
    flex-direction: row;
    min-height: 340px;
  }
}
.panel--alternating .panel__item + .panel__item {
  margin-top: 4.854rem;
}
@media only screen and (min-width: 881px) {
  .panel--alternating .panel__item:nth-child(even) {
    flex-direction: row-reverse;
  }
}
@media only screen and (min-width: 881px) {
  .panel--alternating .panel__item:nth-child(even) .panel__content .panel__cta {
    transform: translateX(50%) translateY(50%);
    position: absolute;
    right: 0;
    left: auto;
  }
}
.panel--alternating .panel__item:nth-child(even) .panel__content .panel__cta:active,
.panel--alternating .panel__item:nth-child(even) .panel__content .panel__cta:focus,
.panel--alternating .panel__item:nth-child(even) .panel__content .panel__cta:hover {
  transform: translate(50%, 50%) scale(1.1);
}
.panel--alternating .panel__item .panel__image,
.panel--alternating .panel__item .panel__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3.236rem;
}
@media only screen and (min-width: 881px) {
  .panel--alternating .panel__item .panel__image,
  .panel--alternating .panel__item .panel__content {
    width: 50%;
  }
}
.panel--alternating .panel__item .panel__image .panel__title,
.panel--alternating .panel__item .panel__content .panel__title,
.panel--alternating .panel__item .panel__image .panel__body,
.panel--alternating .panel__item .panel__content .panel__body {
  text-align: left;
}
@media only screen and (max-width: 1080px) {
  .panel--alternating .panel__item .panel__image {
    height: 40vw;
  }
}
.panel--alternating .panel__item .panel__content {
  position: relative;
}
@media only screen and (min-width: 881px) {
  .panel--alternating .panel__item .panel__content .panel__cta {
    position: absolute;
    left: 0;
    right: auto;
  }
}
.panel__title {
  font-size: 1.265625rem;
  font-size: var(--fz-ratio--two);
}
@media only screen and (min-width: 681px) {
  .panel__title {
    font-size: 1.47015625rem;
    font-size: var(--fz-ratio--two);
  }
}
@media only screen and (min-width: 1281px) {
  .panel__title {
    font-size: 1.69rem;
    font-size: var(--fz-ratio--two);
  }
}
.panel__title a:link,
.panel__title a:visited {
  color: #555;
  box-shadow: inset 0 0 #555;
}
.panel__title a:hover {
  color: #333;
  box-shadow: inset 0 -2px #555;
}
.panel__title .panel__image {
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
/*
        the below line should be commented out because we have exceeded most browsers'
        will-change budget allowance. we can fix this by only using one or
        two properties as a default and then specifying properties every time
        we call the mixin, but at present removing default properties from this
        mixin causes many of the site's styles to break, and actually commenting
        out the line also causes styles to break.
    */
  transition-property: outline-offset, background-color, box-shadow, color, opacity;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}
.panel__title .panel__image:hover {
  opacity: 0.8;
  cursor: pointer;
}
.panel--news .panel__inner {
  max-width: 1180px;
}
.panel--news .panel__item {
  min-height: 340px;
  display: flex;
  flex-direction: column;
  padding: 0;
}
@media only screen and (min-width: 681px) {
  .panel--news .panel__item {
    flex-direction: row;
  }
}
.panel--news .panel__item + .panel__item {
  margin-top: 4.854rem;
}
.panel--news .panel__item > * {
  position: relative;
  z-index: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  background-color: #fff;
  background-position: 50% 50%;
  background-size: cover;
  text-align: left;
  color: #666;
}
@media only screen and (min-width: 681px) {
  .panel--news .panel__item > * {
    width: 50%;
  }
}
.panel--news .panel__item > * h2,
.panel--news .panel__item > * h3,
.panel--news .panel__item > * h4,
.panel--news .panel__item > * p {
  color: inherit;
}
.panel--news .panel__item > * .panel__news_date {
  margin: 0;
  font-weight: 700;
  font-size: 0.888888888888889rem;
  font-size: var(--fz-ratio--minus-one);
}
@media only screen and (min-width: 681px) {
  .panel--news .panel__item > * .panel__news_date {
    font-size: 0.824742268041237rem;
    font-size: var(--fz-ratio--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .panel--news .panel__item > * .panel__news_date {
    font-size: 0.769230769230769rem;
    font-size: var(--fz-ratio--minus-one);
  }
}
.panel--news .panel__content {
  padding: 1.618rem 4%;
}
@media only screen and (min-width: 881px) {
  .panel--news .panel__content {
    padding: 3.236rem 4%;
  }
}
@media only screen and (max-width: 680px) {
  .panel--news .panel__cta {
    position: relative;
    z-index: auto;
    transform: translate(-50%, 0) scale(1);
  }
}
@media only screen and (min-width: 681px) {
  .panel--news .panel__cta,
  .panel--news .panel__cta:link,
  .panel--news .panel__cta:visited {
    position: absolute;
    right: auto;
    bottom: 0;
    left: 0%;
    transform: translate(-50%, 50%) scale(1);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    color: #333;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 400;
    padding: 0.809rem 1.618rem;
    margin-top: 1.618rem;
    box-shadow: 0px 6px 12px -2px rgba(51,51,51,0.08), 0px 2px 24px rgba(51,51,51,0.02);
/*
        the below line should be commented out because we have exceeded most browsers'
        will-change budget allowance. we can fix this by only using one or
        two properties as a default and then specifying properties every time
        we call the mixin, but at present removing default properties from this
        mixin causes many of the site's styles to break, and actually commenting
        out the line also causes styles to break.
    */
    transition-property: outline-offset, transform, color, background-color, opacity, box-shadow;
    transition-duration: 0.6s;
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  .panel--news .panel__cta .icon,
  .panel--news .panel__cta:link .icon,
  .panel--news .panel__cta:visited .icon {
/*
        the below line should be commented out because we have exceeded most browsers'
        will-change budget allowance. we can fix this by only using one or
        two properties as a default and then specifying properties every time
        we call the mixin, but at present removing default properties from this
        mixin causes many of the site's styles to break, and actually commenting
        out the line also causes styles to break.
    */
    transition-property: outline-offset, background-color, box-shadow, color, opacity;
    transition-duration: 0.6s;
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    margin-left: 0.809rem;
    height: 34px;
    width: 14px;
    fill: #555;
  }
  .panel--news .panel__cta:active,
  .panel--news .panel__cta:focus,
  .panel--news .panel__cta:hover {
    background-color: #fff;
    transform: translate(-50%, 50%) scale(1.1);
    box-shadow: 0px 24px 52px 0px rgba(51,51,51,0.22), 0px 3px 16px 0px rgba(51,51,51,0.14);
  }
  .panel--news .panel__cta:active .icon,
  .panel--news .panel__cta:focus .icon,
  .panel--news .panel__cta:hover .icon {
    transform: translateX(4px);
  }
}
.panel--news .panel__title a,
.panel--news .panel__title a:visited {
  color: #555;
}
.panel--news .panel__title a:active,
.panel--news .panel__title a:focus,
.panel--news .panel__title a:hover {
  color: #181818;
}
.panel--news .panel__image {
/*
        the below line should be commented out because we have exceeded most browsers'
        will-change budget allowance. we can fix this by only using one or
        two properties as a default and then specifying properties every time
        we call the mixin, but at present removing default properties from this
        mixin causes many of the site's styles to break, and actually commenting
        out the line also causes styles to break.
    */
  transition-property: outline-offset, background-color, box-shadow, color, opacity;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}
@media only screen and (max-width: 680px) {
  .panel--news .panel__image::before {
    content: "";
    display: block;
    width: 100%;
    padding-bottom: 60%;
  }
}
.panel--news .panel__image:hover {
  opacity: 0.8;
  cursor: pointer;
}
.panel__heading-container {
  position: relative;
  z-index: auto;
  margin: 0 auto;
  width: 92%;
  max-width: 1600px;
  text-align: center;
  color: inherit;
}
.panel__heading {
  margin: 0 auto;
  max-width: 20ch;
  text-transform: uppercase;
  line-height: 0.9;
  font-weight: 400;
  font-family: 'tandelle', sans-serif;
  color: inherit;
}
.panel__heading {
  font-size: 40px;
}
@media screen and (min-width: 320px) {
  .panel__heading {
    font-size: calc(8.860759493670885vw + 11.645569620253163px);
  }
}
@media screen and (min-width: 1900px) {
  .panel__heading {
    font-size: 180px;
  }
}
.panel__summary,
.panel__summary > p {
  margin-top: 0.539333333333333rem;
  margin-right: auto;
  margin-left: auto;
  width: 68ch;
  font-size: 1.265625rem;
  font-size: var(--fz-ratio--two);
  color: #fff;
}
@media only screen and (min-width: 681px) {
  .panel__summary,
  .panel__summary > p {
    font-size: 1.47015625rem;
    font-size: var(--fz-ratio--two);
  }
}
@media only screen and (min-width: 1281px) {
  .panel__summary,
  .panel__summary > p {
    font-size: 1.69rem;
    font-size: var(--fz-ratio--two);
  }
}
.panel--call_to_action .panel__summary,
.panel--call_to_action .panel__summary > p {
  color: #555;
}
* + .panel__body {
  margin-top: 0.809rem;
}
.panel__cta,
.panel__cta:link,
.panel__cta:visited {
  position: absolute;
  right: auto;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%) scale(1);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  color: #333;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 400;
  padding: 0.809rem 1.618rem;
  margin-top: 1.618rem;
  box-shadow: 0px 6px 12px -2px rgba(51,51,51,0.08), 0px 2px 24px rgba(51,51,51,0.02);
/*
        the below line should be commented out because we have exceeded most browsers'
        will-change budget allowance. we can fix this by only using one or
        two properties as a default and then specifying properties every time
        we call the mixin, but at present removing default properties from this
        mixin causes many of the site's styles to break, and actually commenting
        out the line also causes styles to break.
    */
  transition-property: outline-offset, transform, color, background-color, opacity, box-shadow;
  transition-duration: 0.6s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}
.panel__cta .icon,
.panel__cta:link .icon,
.panel__cta:visited .icon {
/*
        the below line should be commented out because we have exceeded most browsers'
        will-change budget allowance. we can fix this by only using one or
        two properties as a default and then specifying properties every time
        we call the mixin, but at present removing default properties from this
        mixin causes many of the site's styles to break, and actually commenting
        out the line also causes styles to break.
    */
  transition-property: outline-offset, background-color, box-shadow, color, opacity;
  transition-duration: 0.6s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  margin-left: 0.809rem;
  height: 34px;
  width: 14px;
  fill: #555;
}
.panel__cta:active,
.panel__cta:focus,
.panel__cta:hover {
  background-color: #fff;
  transform: translate(-50%, 50%) scale(1.1);
  box-shadow: 0px 24px 52px 0px rgba(51,51,51,0.22), 0px 3px 16px 0px rgba(51,51,51,0.14);
}
.panel__cta:active .icon,
.panel__cta:focus .icon,
.panel__cta:hover .icon {
  transform: translateX(4px);
}
.panel__cta__text,
.panel__cta .icon {
  flex: 0 0 auto;
}
.panel__cta .icon {
/*
        the below line should be commented out because we have exceeded most browsers'
        will-change budget allowance. we can fix this by only using one or
        two properties as a default and then specifying properties every time
        we call the mixin, but at present removing default properties from this
        mixin causes many of the site's styles to break, and actually commenting
        out the line also causes styles to break.
    */
  transition-property: outline-offset, background-color, box-shadow, color, opacity;
  transition-duration: 0.6s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  margin-left: 0.809rem;
  height: 34px;
  width: 14px;
  fill: #555;
}
.panel__image_list + .panel__content {
  margin-top: 1.618rem;
}
.panel__image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.panel__image:hover {
  opacity: 0.6;
}
.paragraph_block {
  display: block;
  width: 100%;
}
* + .paragraph_block {
  margin-top: 1.618rem;
}
h1 + .paragraph_block,
h2 + .paragraph_block,
h3 + .paragraph_block {
  margin-top: 0.539333333333333rem;
}
h4 + .paragraph_block {
  margin-top: 0.809rem;
}
.paragraph_block__inner {
  display: flex;
  margin-right: auto;
  margin-left: auto;
  width: 92%;
  max-width: 1180px;
  flex-direction: column;
  width: 92%;
}
.partner_block {
  width: 100%;
  background-color: #fff;
}
.partner_block__list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 92%;
  max-width: 1600px;
  margin: 0 auto 2.156794rem;
}
.partner_block__item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.539333333333333rem;
  margin-top: 2.427rem;
}
.partner_block__image {
  height: auto;
  max-height: 4.4495rem;
  width: auto;
}
.popup-notice {
  width: 100%;
  background-color: #555;
  color: #fff;
}
* + .popup-notice {
  margin-top: 1.618rem;
}
@media only screen and (min-width: 681px) and (max-width: 880px) {
  * + .popup-notice {
    margin-top: 2.156794rem;
  }
}
.popup-notice--red {
  background-color: #e52321;
}
.popup-notice--green {
  background-color: #95af31;
}
.popup-notice--blue {
  background-color: #509ece;
}
.popup-notice--orange {
  background-color: #f7a82d;
}
.popup-notice__inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  width: 92%;
  max-width: 1600px;
  padding: 0.809rem 0;
}
@media only screen and (min-width: 681px) {
  .popup-notice__inner {
    flex-direction: row;
  }
}
.popup-notice__text {
  margin-top: 0;
  width: 100%;
  max-width: 78ch;
  line-height: 1.4;
  font-weight: 700;
  color: inherit;
}
* + .popup-notice__text {
  margin-left: 0.809rem;
}
* + .pricing .membership_pricing {
  margin-top: 3.236rem;
}
.pricing_links {
  display: flex;
  flex: 0 1 auto;
}
* + .pricing_links {
  margin-top: 3.236rem;
}
.pricing_links__inner {
  margin: 0 auto;
}
.pricing_terms {
  display: flex;
  width: 92%;
  max-width: 1600px;
  margin: 0 auto;
}
* + .pricing_terms {
  margin-top: 1.618rem;
}
.pricing_terms__anchor,
.pricing_terms__anchor:visited {
  box-shadow: inset 0 -1px rgba(85,85,85,0.3);
  color: #555;
}
.pricing_terms__anchor:active,
.pricing_terms__anchor:focus,
.pricing_terms__anchor:hover {
  box-shadow: inset 0 -0.2em rgba(85,85,85,0.6);
  color: #333;
}
.related_centres {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
* + .related_centres {
  margin-top: 3.236rem;
}
.related_centres__heading {
  display: flex;
  margin-right: auto;
  margin-left: auto;
  width: 92%;
  max-width: 1600px;
  font-size: 1.423828125rem;
  font-size: var(--fz-ratio--three);
}
@media only screen and (min-width: 681px) {
  .related_centres__heading {
    font-size: 1.782564453125rem;
    font-size: var(--fz-ratio--three);
  }
}
@media only screen and (min-width: 1281px) {
  .related_centres__heading {
    font-size: 2.197000000000001rem;
    font-size: var(--fz-ratio--three);
  }
}
.related_centres .grid {
  margin-top: 1.618rem;
}
.social {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
}
@media only screen and (min-width: 681px) {
  .social {
    flex-direction: row;
  }
}
.social__title {
  display: none;
}
.social__list {
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.social__list_item {
  margin-left: 0.809rem;
}
.social__list_item:first-child {
  margin-left: 0;
}
.social__link {
  display: flex;
  align-items: center;
}
.social__link .icon {
  height: 38px;
  width: 38px;
/*
        the below line should be commented out because we have exceeded most browsers'
        will-change budget allowance. we can fix this by only using one or
        two properties as a default and then specifying properties every time
        we call the mixin, but at present removing default properties from this
        mixin causes many of the site's styles to break, and actually commenting
        out the line also causes styles to break.
    */
  transition-property: outline-offset, fill;
  transition-duration: 0.14s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}
.social__link--newsletter {
  margin-left: 1.077588rem;
}
.navigation_menu .social__list_item {
  margin-left: 1.077588rem;
}
.navigation_menu .social__list_item:first-child {
  margin-left: 0;
}
.navigation_menu .social__link:link,
.navigation_menu .social__link:visited {
  color: #fff;
}
.navigation_menu .social__link:link .icon,
.navigation_menu .social__link:visited .icon {
  fill: #fff;
}
.navigation_menu .social__link:active,
.navigation_menu .social__link:focus,
.navigation_menu .social__link:hover {
  color: #e52321;
}
.navigation_menu .social__link:active .icon,
.navigation_menu .social__link:focus .icon,
.navigation_menu .social__link:hover .icon {
  fill: #e52321;
}
.navigation_menu .social__link .icon {
  width: auto;
  height: 42px;
  margin-left: 0.539333333333333rem;
}
.sub_footer {
  position: relative;
  z-index: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 92%;
  max-width: 1600px;
  padding: 0.809rem 0;
  font-size: 0.888888888888889rem;
  font-size: var(--fz-ratio--minus-one);
}
@media only screen and (min-width: 681px) {
  .sub_footer {
    flex-direction: row;
  }
}
@media only screen and (min-width: 681px) {
  .sub_footer {
    align-items: center;
  }
}
@media only screen and (min-width: 681px) {
  .sub_footer {
    font-size: 0.824742268041237rem;
    font-size: var(--fz-ratio--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .sub_footer {
    font-size: 0.769230769230769rem;
    font-size: var(--fz-ratio--minus-one);
  }
}
.sub_footer::before {
  content: '';
  position: absolute;
  top: 0;
  height: 4px;
  width: 100%;
  background-color: #333;
}
.sub_footer__navigation {
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.sub_footer__navigation__item {
  flex: 0 0 auto;
  display: inline-block;
  margin-right: 1.618rem;
  line-height: 2;
}
.sub_footer__navigation__item:last-of-type {
  margin-right: 0;
}
.sub_footer__navigation__anchor {
  color: #333;
}
.sub_footer__navigation__anchor,
.sub_footer__navigation__anchor:link,
.sub_footer__navigation__anchor:visited {
  box-shadow: inset 0 -1px rgba(51,51,51,0.2);
  color: #333;
}
.sub_footer__navigation__anchor:hover {
  box-shadow: inset 0 -0.24em rgba(102,102,102,0.2);
  color: #333;
}
.sub_footer__navigation__anchor:active,
.sub_footer__navigation__anchor:focus {
  box-shadow: inset 0 -0.14em rgba(24,24,24,0.2);
  color: #181818;
}
.sub_footer__copyright {
  flex: 0 0 auto;
  display: inline-block;
}
* + .sub_footer__copyright {
  margin-top: 0.809rem;
}
@media only screen and (min-width: 681px) {
  * + .sub_footer__copyright {
    margin-top: 0;
  }
  * + .sub_footer__copyright {
    margin-left: 1.618rem;
  }
}
.timetable-embed {
  width: 92%;
  max-width: 1600px;
  margin-right: auto;
  margin-left: auto;
}
* + .timetable-embed {
  margin-top: 3.236rem;
}
.timetable-embed__download {
  width: 100%;
}
* + .timetable-embed__download {
  margin-top: 1.618rem;
}
.timetable-embed__inner {
  width: 100%;
  text-align: center;
  overflow: hidden;
}
.timetable-embed__timetable {
  width: 100%;
}
.timetable-embed__timetable--swimming {
  overflow: auto;
  display: flex;
  align-content: center;
  justify-content: center;
}
.timetable-embed__timetable--swimming > * {
  flex: 0 0 auto;
}
.timetable-embed__gladstone_timetable_replacement_text {
  position: relative;
  z-index: 100;
  padding: 0.809rem 0 0;
  width: 100%;
  background-color: #fff;
  text-align: center;
}
.timetable-embed__gladstone_timetable_replacement_text p {
  margin: 0 auto;
  max-width: 80ch;
  padding: 0 1.8em;
}
.timetable-embed__frame {
  height: 36.405rem;
  width: 100%;
  border: none;
  background-color: #fff;
}
.timetable-embed__frame--gladstone {
  height: 220vh;
  overflow-y: scroll;
}
.timetable_preview {
  display: block;
  margin-right: auto;
  margin-left: auto;
  width: 92%;
  max-width: 1600px;
}
* + .timetable_preview {
  margin-top: 3.236rem;
}
.timetable_preview__inner {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  box-shadow: 0 12px 17px -5px rgba(31,31,31,0.2), 0 35px 110px 0px rgba(31,31,31,0.1);
}
@media only screen and (min-width: 881px) {
  .timetable_preview__inner {
    flex-direction: row;
  }
}
@media only screen and (min-width: 881px) {
  .timetable_preview__image,
  .timetable_preview__information {
    flex: 0 0 50%;
  }
}
.timetable_preview__image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media only screen and (max-width: 880px) {
  .timetable_preview__image {
    padding-bottom: 50%;
  }
}
.timetable_preview__information {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: #fff;
  padding: 1.618rem 4vw;
}
@media only screen and (min-width: 881px) {
  .timetable_preview__information {
    padding: 3.236rem 1.618rem;
  }
}
.timetable_preview__summary {
  width: 100%;
  max-width: 78ch;
}
* + .timetable_preview__summary {
  margin-top: 0.539333333333333rem;
}
.two_columns {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-right: auto;
  margin-left: auto;
  width: 92%;
  max-width: 1600px;
}
@media only screen and (min-width: 881px) {
  .two_columns {
    flex-direction: row;
  }
}
@media only screen and (min-width: 881px) {
  .two_columns {
    align-items: flex-start;
  }
}
* + .two_columns {
  margin-top: 3.236rem;
}
* + .two_columns > * + * {
  margin-top: 1.618rem;
}
@media only screen and (min-width: 881px) {
  * + .two_columns > * + * {
    margin-top: 0;
  }
}
.two_columns__block {
  flex: 0 0 auto;
  width: 100%;
}
@media only screen and (min-width: 881px) {
  .two_columns__block {
    flex: 0 0 49%;
  }
}
.two_columns__block > .image_chooser {
  max-width: 100%;
}
.video_embed {
  margin-right: auto;
  margin-left: auto;
  width: 92%;
}
* + .video_embed {
  margin-top: 3.236rem;
}
.video_embed__inner,
.video_embed__summary {
  position: relative;
  z-index: auto;
  width: 100%;
  max-width: 1600px;
}
.video_embed__inner {
  margin-right: auto;
  margin-left: auto;
  width: 50%;
  margin: 0 auto;
}
@media only screen and (max-width: 1080px) {
  .video_embed__inner {
    width: 100%;
  }
}
.video_embed__wrapper {
  position: relative;
  z-index: auto;
  padding-bottom: 56.25% /* 16:9 ratio */;
}
.video_embed padding-top 0 {
  height: 0;
}
.video_embed__content {
  position: absolute;
  z-index: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.video_embed__summary {
  font-weight: 700;
  font-size: 0.888888888888889rem;
  font-size: var(--fz-ratio--minus-one);
}
* + .video_embed__summary {
  margin-top: 0.809rem;
}
@media only screen and (min-width: 681px) {
  .video_embed__summary {
    font-size: 0.824742268041237rem;
    font-size: var(--fz-ratio--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .video_embed__summary {
    font-size: 0.769230769230769rem;
    font-size: var(--fz-ratio--minus-one);
  }
}
.virtual_tour {
  width: 92%;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
}
* + .virtual_tour {
  margin-top: 3.236rem;
}
.virtual_tour__heading {
  width: 100%;
  font-size: 1.423828125rem;
  font-size: var(--fz-ratio--three);
}
@media only screen and (min-width: 681px) {
  .virtual_tour__heading {
    font-size: 1.782564453125rem;
    font-size: var(--fz-ratio--three);
  }
}
@media only screen and (min-width: 1281px) {
  .virtual_tour__heading {
    font-size: 2.197000000000001rem;
    font-size: var(--fz-ratio--three);
  }
}
.virtual_tour iframe {
  box-shadow: 0 12px 17px -5px rgba(31,31,31,0.2), 0 35px 110px 0px rgba(31,31,31,0.1);
}
* + .virtual_tour iframe {
  margin-top: 0.809rem;
}
.wysiwyg {
  margin: 3.236rem auto 0;
  width: 92%;
  max-width: 880px;
}
.panel--intro + .wysiwyg,
.heading_block + .wysiwyg {
  margin-top: 1.618rem;
}
.wysiwyg__heading {
  margin-bottom: 1.077588rem;
  width: 100%;
  display: block;
}
.wysiwyg__text {
  width: 100%;
}
.article_detail .page_heading__title {
  font-size: 40px;
}
@media screen and (min-width: 320px) {
  .article_detail .page_heading__title {
    font-size: calc(5.69620253164557vw + 21.77215189873418px);
  }
}
@media screen and (min-width: 1900px) {
  .article_detail .page_heading__title {
    font-size: 130px;
  }
}
* + .article_detail__meta {
  margin-top: 0.3236rem;
}
.article_detail__meta_list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.article_detail__meta_item {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-right: 1.618rem;
}
.article_detail__meta_label {
  font-weight: 700;
}
* + .article_detail__meta_value {
  margin-left: 0.269666666666667rem;
}
.article_detail__meta_value--anchor,
.article_detail__meta_value--anchor:visited {
  box-shadow: inset 0 -1px rgba(85,85,85,0.3);
  color: #555;
}
.article_detail__meta_value--anchor:active,
.article_detail__meta_value--anchor:focus,
.article_detail__meta_value--anchor:hover {
  box-shadow: inset 0 -0.2em rgba(85,85,85,0.6);
  color: #333;
}
.centre_index__list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.centre_index__item,
.centre_index .card {
  flex: 0 0 100%;
}
@media only screen and (min-width: 681px) {
  .centre_index__item,
  .centre_index .card {
    flex: 0 0 48%;
  }
}
@media only screen and (min-width: 1081px) {
  .centre_index__item,
  .centre_index .card {
    flex: 0 0 30.666666666666668%;
  }
}
@media only screen and (min-width: 681px) and (max-width: 1080px) {
  .centre_index__item:nth-child(even),
  .centre_index .card:nth-child(even) {
    margin-left: 4%;
  }
}
@media only screen and (min-width: 1081px) {
  .centre_index__item:nth-child(3n+2),
  .centre_index .card:nth-child(3n+2),
  .centre_index__item:nth-child(3n+3),
  .centre_index .card:nth-child(3n+3) {
    margin-left: 4%;
  }
}
@media only screen and (max-width: 680px) {
  .centre_index__item:nth-child(n+2),
  .centre_index .card:nth-child(n+2) {
    margin-top: 4%;
  }
}
@media only screen and (max-width: 1080px) {
  .centre_index__item:nth-child(n+3),
  .centre_index .card:nth-child(n+3) {
    margin-top: 4%;
  }
}
@media only screen and (min-width: 1081px) {
  .centre_index__item:nth-child(n+4),
  .centre_index .card:nth-child(n+4) {
    margin-top: 4%;
  }
}
@media only screen and (min-width: 681px) {
  .centre_index__item:last-child,
  .centre_index .card:last-child {
    margin-right: auto;
  }
}
* + .event_detail__meta {
  margin-top: 0.3236rem;
}
.event_detail__meta_list {
  display: block;
  box-shadow: 0 1px 0.06rem rgba(51,51,51,0.14);
  border-radius: 5px;
  width: 100%;
  max-width: 26rem;
  background-color: #fff;
  padding: 0.539333333333333rem 0.809rem;
}
* + .event_detail__meta_list {
  margin-top: 0.539333333333333rem;
}
.event_detail__meta_item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  font-size: 0.888888888888889rem;
  font-size: var(--fz-ratio--minus-one);
}
* + .event_detail__meta_item {
  margin-top: 0.4045rem;
}
@media only screen and (min-width: 681px) {
  .event_detail__meta_item {
    font-size: 0.824742268041237rem;
    font-size: var(--fz-ratio--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .event_detail__meta_item {
    font-size: 0.769230769230769rem;
    font-size: var(--fz-ratio--minus-one);
  }
}
.event_detail__meta_label {
  width: 3.5rem;
  font-weight: 700;
}
* + .event_detail__meta_value {
  margin-left: 0.269666666666667rem;
}
.event_detail__meta_value > p {
  font-size: 0.888888888888889rem;
  font-size: var(--fz-ratio--minus-one);
}
@media only screen and (min-width: 681px) {
  .event_detail__meta_value > p {
    font-size: 0.824742268041237rem;
    font-size: var(--fz-ratio--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .event_detail__meta_value > p {
    font-size: 0.769230769230769rem;
    font-size: var(--fz-ratio--minus-one);
  }
}
.event_detail__meta_value--anchor,
.event_detail__meta_value--anchor:visited {
  box-shadow: inset 0 -1px rgba(85,85,85,0.3);
  color: #555;
}
.event_detail__meta_value--anchor:active,
.event_detail__meta_value--anchor:focus,
.event_detail__meta_value--anchor:hover {
  box-shadow: inset 0 -0.2em rgba(85,85,85,0.6);
  color: #333;
}
.job_detail .page_heading__title {
  font-size: 40px;
}
@media screen and (min-width: 320px) {
  .job_detail .page_heading__title {
    font-size: calc(5.69620253164557vw + 21.77215189873418px);
  }
}
@media screen and (min-width: 1900px) {
  .job_detail .page_heading__title {
    font-size: 130px;
  }
}
* + .job_detail__meta {
  margin-top: 0.3236rem;
}
.job_detail__meta_list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.job_detail__meta_item {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-right: 1.618rem;
}
.job_detail__meta_label {
  font-weight: 700;
}
* + .job_detail__meta_value {
  margin-left: 0.269666666666667rem;
}
.job_detail__meta_value--anchor,
.job_detail__meta_value--anchor:visited {
  box-shadow: inset 0 -1px rgba(85,85,85,0.3);
  color: #555;
}
.job_detail__meta_value--anchor:active,
.job_detail__meta_value--anchor:focus,
.job_detail__meta_value--anchor:hover {
  box-shadow: inset 0 -0.2em rgba(85,85,85,0.6);
  color: #333;
}
.search_results__form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 92%;
  max-width: 1600px;
  border-radius: 5px;
  background-color: #e5e9f1;
  padding: 1.077588rem 4vw 1.618rem;
}
* + .search_results__form {
  margin-top: 3.236rem;
}
@media only screen and (min-width: 481px) {
  .search_results__form {
    padding: 1.077588rem 1.618rem 1.618rem;
  }
}
.search_results__form_label {
  flex: 0 0 100%;
}
.search_results__form_search_term {
  flex: 0 0 100%;
  border: none;
  border-radius: 5px;
  color: #181818;
  background-color: #fff;
  padding: 0.4045rem 0.809rem;
}
@media only screen and (min-width: 481px) {
  .search_results__form_search_term {
    flex: 1;
  }
}
* + .search_results__form_search_term {
  margin-top: 0.20225rem;
}
@media only screen and (min-width: 481px) {
  .search_results__form_search_term {
    border-radius: 5px 0 0 5px;
  }
}
.search_results__form_submit {
  flex: 0 0 auto;
  border-radius: 5px;
  background-color: #777;
  text-shadow: 0 0 0.1em #333;
  color: #fff;
}
* + .search_results__form_submit {
  margin-top: 0.809rem;
}
@media only screen and (min-width: 481px) {
  * + .search_results__form_submit {
    margin-top: 0.20225rem;
  }
}
@media only screen and (max-width: 680px) {
  .search_results__form_submit {
    margin-left: auto;
  }
}
@media only screen and (min-width: 481px) {
  .search_results__form_submit {
    border-radius: 0 5px 5px 0;
  }
}
.search_results__form_submit:active,
.search_results__form_submit:focus,
.search_results__form_submit:hover {
  background-color: #666;
  color: #fff;
}
.search_results__results {
  display: flex;
  margin-right: auto;
  margin-left: auto;
  width: 92%;
  max-width: 100%;
}
.search_results__results_item {
  background-color: #fff;
}
.search_results__results_item_image_anchor {
  display: block;
}
.search_results__results_item_text {
  padding: 0.809rem 4vw;
}
@media only screen and (min-width: 481px) {
  .search_results__results_item_text {
    padding: 0.809rem;
  }
}
.search_results__results_item_title {
  font-size: 1.265625rem;
  font-size: var(--fz-ratio--two);
}
@media only screen and (min-width: 681px) {
  .search_results__results_item_title {
    font-size: 1.47015625rem;
    font-size: var(--fz-ratio--two);
  }
}
@media only screen and (min-width: 1281px) {
  .search_results__results_item_title {
    font-size: 1.69rem;
    font-size: var(--fz-ratio--two);
  }
}
.search_results__results_item_title_anchor,
.search_results__results_item_title_anchor:active,
.search_results__results_item_title_anchor:focus,
.search_results__results_item_title_anchor:hover {
  color: #555;
}
